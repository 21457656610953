import { Link } from "react-router-dom";
import React from "react";

const AnnouncementBar = () => {
  return (
    <Link
      to="/ContactUs"
      className="bg-[#900c3e] w-full h-10 fixed top-0 z-50 flex justify-center items-center text-white whitespace-pre"
    >
      Commercial? Request a Quote! <span aria-hidden="true">→</span>
    </Link>
  );
};

export default AnnouncementBar;

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const PicLeft = ({ subtitle, title, description, button, picture, color }) => {
  return (
    <div className={`relative bg-[${color}]`}>
      <motion.div
        initial={{ scale: 0 }}
        transition={{ ease: "easeOut", duration: 0.5 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        className="relative h-80 overflow-hidden lg:absolute lg:left-0 lg:h-full lg:w-1/2"
      >
        <img
          className="h-full w-full object-cover py-8 px-8"
          src={picture}
          alt=""
        />
      </motion.div>
      <div className="relative mx-auto max-w-full py-4 lg:py-20 lg:px-16">
        <motion.div
          initial={{ scale: 0 }}
          transition={{ ease: "easeOut", duration: 0.6 }}
          whileInView={{ scale: 1 }}
          viewport={{ once: true }}
          className="pr-6 pl-6 md:ml-auto lg:w-7/12 lg:pl-24 lg:pr-0 xl:pl-32 "
        >
          <h2 className="text-base lg:ml-12 font-bold leading-7 text-[#474d53]">
            {subtitle}
          </h2>
          <p className="mt-2 lg:ml-12 text-3xl font-base tracking-wide text-[#900c3e] sm:text-5xl">
            {title}
          </p> 
          <p className="mt-6 lg:ml-12 text-xl font-base tracking-wide leading-7 text-[#474d53] whitespace-pre-line">
            {description}
          </p>
          <div className="mt-8  lg:ml-12">
            <Link
              to="/"
              className="inline-flex rounded-3xl bg-[#900c3e] px-4 py-3.5 mb-4 text-md font-bold text-white shadow-xl hover:bg-[#82123f] transition delay-50 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 sm:text-lg"
            >
              {button}
            </Link>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default PicLeft;

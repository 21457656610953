import IndustryTypes from "../IndustryTypes";
import Hero from "../Hero";
import Includes from "../Includes";
import MoreInfoService from "../MoreInfoService";
import RequestQuote from "../RequestQuote";
import PicRight from "../PicRight";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import hospital from "../../images/hospitals.jpg"


const Medical = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = new URLSearchParams(location.search).get("title");
  const subtitle = new URLSearchParams(location.search).get("subtitle");
  const image = new URLSearchParams(location.search).get("image");
  const description = new URLSearchParams(location.search).get("description");
  
  const cards = [
    {
      name: "Why Tower Cleanings Services? ",
      description: 
"At Tower Cleaning Services, we take every precaution to ensure that your space is safe, clean, and free from harmful pathogens. We believe that providing high-quality cleaning services involves using the best disinfectants and cleaners available, which is why we only use EPA-certified and ammonia-free products. These products are not only effective at eliminating germs but are also safe for the environment and allergy-friendly. We also understand the importance of protecting our staff and clients, which is why we always wear proper CDC-approved personal protective equipment, including masks, visors, gloves, and specialty clothing. This ensures that our team is protected from exposure to any potentially harmful substances and that we do not spread germs from one area to another. Our team also provides air purification services and implements steam cleaning as necessary to ensure that your space is thoroughly disinfected. We focus on high-touch and high-traffic areas where germs are most likely to spread and make sure that these areas are thoroughly cleaned and disinfected. Finally, we dispose of all cleaning materials, including mops and cloths, to ensure that we are not spreading bacteria from job to job. We understand that a clean and safe environment is essential for the health and well-being of our clients and their employees, and we take every step necessary to ensure that we provide the highest quality cleaning services possible."    },
    {
      name: "Our Anti-Microbial Cleanings Services",
      description:
      "The anti-microbial cleaning process involves a multi-step approach that includes the use of EPA-approved disinfectants and sanitizers, as well as the implementation of cleaning protocols that are designed to target high-touch areas such as doorknobs, handles, and other surfaces. Tower Cleaning Services also uses electrostatic sprayers to apply the disinfectant, which provides better coverage and helps to ensure that all surfaces are thoroughly disinfected."
    },
  ];

  return (
    <div>
      <Hero
        title={title}
        subtitle={subtitle}
        button={"Call to Book →"}
        link={"/"}
        picture={hospital}
      />
      <PicRight
        subtitle={"Customized Services for Clean and Sanitary Environments"}
        title={"Medical Facility Cleaning Solutions"}
        description={`We understand the importance of maintaining a clean, sanitary environment in medical facilities. That's why we offer expert cleaning solutions designed to meet the unique needs of healthcare providers, from hospitals and clinics to dental offices and more.\n\nOur team of experienced cleaning professionals is trained to use the latest techniques and equipment to ensure your facility is spotless and disinfected, promoting the health and safety of patients, staff, and visitors. From exam rooms and waiting areas to surgical suites and labs, we customize our cleaning plans to meet your specific requirements and exceed your expectations. Contact us today to learn more about our expert cleaning solutions for medical facilities.`}
        link={"/contactUs"}
        button={"Contact Us"}
        picture={
          "https://images.unsplash.com/photo-1583947581924-860bda6a26df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1167&q=80"
        }
        color={"#f5f5f5"}
      />
      <Includes />
      <>
      <div className="relative isolate overflow-hidden py-10 ">
        <svg
          viewBox="0 0 1097 845"
          aria-hidden="true"
          className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
        >
          <path
            fill="url(#7c63f5ae-130c-4c0f-963f-50ac7fe8d2e1)"
            fillOpacity=".2"
            d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
          />
        </svg>
        <div className="mx-auto px-6 lg:px-32">
          <motion.h2
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="mb-5 text-3xl font-base tracking-wide text-[#900c3e] sm:text-6xl"
          >
            Medical Facility Cleaning Services
          </motion.h2>
          <div className="mx-auto max-w-2xl lg:mx-0"></div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 bg-[#f5f5f5] gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:gap-1">
            {cards.map((card) => (
              <div key={card.name} className="justify-center flex gap-x-4] p-6">
                <div className="text-base leading-7 ">
                  <motion.h3
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.6 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="font-bold text-black hover:text-[#82123f]"
                  >
                    {card.name}
                  </motion.h3>
                  <motion.p
                    id="faqText"
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.7 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="mt-2  text-[#474d53] whitespace-pre-line"
                  >
                    {card.description}
                  </motion.p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <motion.div
        initial={{ scale: 0 }}
        transition={{ ease: "easeOut", duration: 0.8 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        className="text-center mb-10"
      >
      </motion.div>
    </>
      <RequestQuote />
    </div>
  );
};

export default Medical;

import Services from "./pages/Services"
import AnnouncementBar from "./components/AnnouncementBar"
import { Routes, Route } from "react-router-dom"
import Locations from "./pages/Locations"
import Footer from "./components/Footer"
import AboutUs from "./pages/AboutUs"
import Nav from "./components/Nav"
import Home from "./pages/Home"
import Industries from "./pages/Industries"
import IndustryDetails from "./pages/IndustryDetails"
import ServiceDetails from "./pages/ServiceDetails"
import LocationDetails from "./pages/LocationDetails"
import ContactUs from "./components/Contact"
import Booking from "./components/Booking"
import Commercial from "./components/Commercial"
import Residential from "./components/Residential"
import Offices from "./components/Facilities/Offices"
import Medical from "./components/Facilities/Medical"
import Industrial from "./components/Facilities/Industrial"
import Educational from "./components/Facilities/Educational"
import Banks from "./components/Facilities/Banks"
import Government from "./components/Facilities/Government"
import Church from "./components/Facilities/Church"
import Health from "./components/Facilities/Health"
import CoWork from "./components/Facilities/CoWork"
import Retail from "./components/Facilities/Retail"
import Restaurant from "./components/Facilities/Restaurant"
import GasStation from "./components/Facilities/Gas-Station"
import AtlBooking from "./bookingatl"

function App() {
  return (
    <div className="App">
      <AnnouncementBar />
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/commercial" element={<Commercial />} />
        <Route path="/services/residential" element={<Residential />} />
        <Route path="/services/residential/booking" element={<Booking />} />
        <Route path="/services/residential/atlanta/booking" element={<AtlBooking />} />
        <Route path="/industries" element={<Industries />} />

        <Route path="/industries/offices-buildings" element={<Offices />} />
        <Route path="/industries/medical-facilities" element={<Medical />} />
        <Route path="/industries/industrial-manufacturing" element={<Industrial />} />
        <Route path="/industries/education-schools" element={<Educational />} />
        <Route path="/industries/banks-financial" element={<Banks />} />
        <Route path="/industries/government" element={<Government />} />
        <Route path="/industries/religious" element={<Church />} />
        <Route path="/industries/health-clubs" element={<Health />} />
        <Route path="/industries/events" element={<CoWork />} />
        <Route path="/industries/retail" element={<Retail />} />
        <Route path="/industries/restaurant-hospitality" element={<Restaurant />} />
        <Route path="/industries/gas-station" element={<GasStation />} />

        <Route path="/locations" element={<Locations />} />
        <Route path="/locations/:location" element={<LocationDetails />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default App

const Footer = () => {
  const navigation = [
    {
      name: "Facebook",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1./photos/6195277/pexels-photo-6195277.jpeg791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Twitter",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    // {
    //   name: "GitHub",
    //   href: "#",
    //   icon: (props) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path
    //         fillRule="evenodd"
    //         d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   ),
    // },
    {
      name: "YouTube",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ];

  const features = {
    "About Us": {
      description:
        "We offer expert cleaning solutions for government buildings, tailored to meet your unique needs. Our experienced team of cleaning professionals uses...",
    },
    "Find Us": {
      addressTitle: "Atlanta Office",
      address: "730 Peachtree Street NE, #570, Atlanta, GA, 30080",
      google:
        "https://www.google.com/search?sxsrf=APwXEdeeXdmfAxcHsVhsZYt8Hcj9ZbZZTQ%3A1680861260290&q=Tower%20Maids%20LLC&stick=H4sIAAAAAAAAAONgU1I1qLCwMLM0NzY1Tk5MTEw2MU-xMqgwMzdLMkhONjM2MjVOszBKXcTKH5Jfnlqk4JuYmVKs4OPjDABKeGB5OwAAAA&mat=CSiuIGL23pRD&ved=2ahUKEwjxgdiSwJf-AhWQSjABHSDlD0kQrMcEegQIKhAH&bshm=lbsc/1",
      yelp: "https://www.yelp.com/biz/tower-maids-louisville?utm_campaign=www_business_share_popup&utm_medium=copy_link&utm_source=(direct)",
    },
    "Contact Us": {
      email: "info@towercleaning.com",
      phone: "9124553605",
    },
    Information: {
      faqLink: "Frequently Asked Questions",
      blogLink: "Cleaning Blog",
      locationsLink: "Locations We Serve",
    },
    // {
    //   name: "About Us",
    //   description:
    //     "We offer expert cleaning solutions for government buildings, tailored to meet your unique needs. Our experienced team of cleaning professionals uses... ",
    //   href: "/about-us",
    // },
    // {
    //   name: "Find Us",
    //   addressTitle: "Louisville Office",
    //   address: "312 S 4th St. Suite 700",
    //   google: "Google Reviews",
    //   yelp: "Yelp",
    //   // description: `Louisville Office\n312 S 4th St. Suite 700\n\n\nGoogle Reviews\n\nYelp`,
    //   // href: "#",
    // },
    // {
    //   name: "Contact Us",
    //   email: "info@towercleaning.com",
    //   phone: "9124553605",
    //   // description: "info@towercleaning.com\n\n912-455-3605\n\n",
    //   // href: "#",
    // },
    // {
    //   name: "Information",
    //   faqLink: "Frequently Asked Questions",
    //   blogLink: "Cleaning Blog",
    //   locationsLink: "Locations We Serve",
    // description:
    //   "Frequently Asked Questions\n\nCleaning Blog\n\nLocations We Serve",
    // href: "#",
    // },
  };

  return (
    <footer className="bg-white">
      <div className="bg-[#474d53] py-10 sm:py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-1">
          <div className="mx-auto  max-w-2xl s lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-14 gap-y-16 lg:max-w-none lg:grid-cols-4 pr-4 pl-4">
              {/* {features.map((features) => (
                ))} */}
              <div key={features["About Us"]} className="flex flex-col ">
                <h6 className="text-xl font-bold tracking-wide mb-2 leading-7 text-[#ffffff] border-b pb-2">
                  About Us
                </h6>
                <dd className="mt-2 flex flex-auto flex-col text-xl leading-7 text-[#ffffff] whitespace-pre-line">
                  <p id="faqText" className="flex-auto">
                    {features["About Us"].description}
                  </p>
                  <p className="mt-6">
                    <a
                      href="/about-us"
                      className="text-md font-light tracking-wide leading-6 text-[#ffffff] hover:text-[#900c3e]"
                    >
                      Read More <span aria-hidden="true">→</span>
                    </a>
                  </p>
                </dd>
              </div>
              <div key={features.name} className="flex flex-col ">
                <h6 className="text-xl font-bold tracking-wide mb-2 leading-7 text-[#ffffff] border-b pb-2">
                  Find Us
                </h6>
                <dd className="mt-2 flex flex-auto flex-col text-xl leading-7 text-[#ffffff] whitespace-pre-line">
                  <p id="faqText" className="flex-auto">
                    {features["Find Us"].addressTitle}
                    <br />
                    {features["Find Us"].address}
                    <br />
                    <br />
                    <a
                      href={features["Find Us"].google}
                      className="text-md font-light tracking-wide leading-6 text-[#ffffff] hover:text-[#900c3e]"
                    >
                      Google Reviews <span aria-hidden="true">→</span>
                    </a>
                    <br />
                    <br />
                    <a
                      href={features.yelp}
                      className="text-md font-light tracking-wide leading-6 text-[#ffffff] hover:text-[#900c3e]"
                    >
                      Yelp <span aria-hidden="true">→</span>
                    </a>
                  </p>
                </dd>
              </div>
              <div key={features.name} className="flex flex-col ">
                <h6 className="text-xl font-bold tracking-wide mb-2 leading-7 text-[#ffffff] border-b pb-2">
                  Contact Us
                </h6>
                <dd className="mt-2 flex flex-auto flex-col text-xl leading-7 text-[#ffffff] whitespace-pre-line">
                  <p id="faqText"   className="text-md tracking-wide leading-6 hover:text-[#900c3e]">
                    {"info@towercleanings.com"}
                  </p>
                  <p id="faqText"   className="text-md tracking-wide leading-6 hover:text-[#900c3e]">
                    {"912-455-3650"}
                  </p>
                  <p className="mt-6">
                    <a
                      href={features.href}
                      className="text-md font-light tracking-wide leading-6 text-[#ffffff] hover:text-[#900c3e]"
                    >
                      Read More <span aria-hidden="true">→</span>
                    </a>
                  </p>
                </dd>
              </div>
              <div key={features.name} className="flex flex-col ">
                <h6 className="text-xl font-bold tracking-wide mb-2 leading-7 text-[#ffffff] border-b pb-2">
                  Information
                </h6>
                <dd className="mt-2 flex flex-auto flex-col text-xl leading-7 text-[#ffffff] whitespace-pre-line">
                  <p id="faqText" className="flex-auto">
                  <a
            href="/ContactUs"
            className="text-md tracking-wide leading-6 hover:text-[#900c3e]"
          >
            Contact Form <span aria-hidden="true">&rarr;</span>
          </a>
                  </p>
                  <p id="faqText" className="flex-auto text-md tracking-wide leading-6 hover:text-[#900c3e]">
                  <a
            href="/locations"
          >
            Locations Served <span aria-hidden="true">&rarr;</span>
          </a>
                  </p>
                  <p id="faqText" className="flex-auto text-md tracking-wide leading-6 hover:text-[#900c3e]">
                  <a
            href="https://www.google.com/search?q=Tower+Maids+LLC&ludocid=7452064053802039342&lpsid=CIHM0ogKEICAgIDhnZeIiwE&source=sh/x/localposts&lsig=AB86z5XYqbG4I3u081NDLPIRZ_sW&bshm=lbsc/1#cobssid=s"
          >
            Latest Blog Post <span aria-hidden="true">&rarr;</span>
          </a>
                  </p>
                  <p id="faqText" className="flex-auto">
                    {features.description}
                  </p>
                  <p className="mt-6">
                    <a
                      href={features.href}
                      className="text-md font-light tracking-wide leading-6 text-[#ffffff] hover:text-[#900c3e]"
                    >
                      Read More <span aria-hidden="true">→</span>
                    </a>
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      {/* Social Footer */}
      <div className="mx-auto py-6 px-6 md:flex md:items-center md:justify-between lg:px-8 bg-[#293135]">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-gray-500">
            &copy; 2023 ~ Tower Cleaning Services
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import Logos from "../components/Logos";
import WhyUsBox from "../components/WhyUsBox";
import IndustriesServe from "../components/IndustriesServe";
import Hero from "../components/Hero";
import BlogPostForm from "../components/BlogPostForm";
import SimpleSlider from "../components/SimpleSlider";
import PicRight from "../components/PicRight";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero
        title={"Tower Cleaning Services"}
        subtitle={`Commercial Cleaning Services in Atlanta, GA and Louisville, KY\nYour Gateway to Immaculate and Inviting Spaces`}
        button={"Request A Quote"}
        link={"/ContactUs"}
        picture={
          "https://images.pexels.com/photos/6195277/pexels-photo-6195277.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        }
      />
      <Logos />
      <PicRight
        subtitle={"Expert Cleaning Solutions for Every Industry"}
        title={"Clean Spaces, Healthy Places"}
        description={`Tower Cleaning Services leads the way in eco-friendly and health-conscious commercial cleaning solutions.\n\nBased in Atlanta, GA & Louisville, KY, our innovative cleaning company offers businesses comprehensive green janitorial services, creating a healthier environment for your customers and employees.`}
        button={"Book Residential!"}
        link={"/services/residential/atlanta/booking"}
        picture={
          "https://images.unsplash.com/photo-1583947581924-860bda6a26df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1167&q=80"
        }
        color={"#f5f5f5"}
      />
      <WhyUsBox />
      <IndustriesServe />
      <SimpleSlider />
    </div>
  );
};

export default Home;

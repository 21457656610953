import OurServices from "../components/OurServices";
import Hero from "../components/Hero";
import PicLeft from "../components/PicLeft";
import RequestQuote from "../components/RequestQuote";
import PicRight from "../components/PicRight";
import { useEffect } from "react";
import LocationIcons from "../components/LocationIcons";
import Cities from "../images/Cities.jpg"

const Locations = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero
        title={"Locations"}
        subtitle={
          "Discover Our Service Locations and Experience Top-Notch Cleaning"
        }
        button={"See Locations"}
        link={"/"}
        picture={Cities}
      />
      <LocationIcons />
      <PicRight
        subtitle={
          "Comprehensive Eco-Friendly Cleaning Solutions for Homes and Businesses"
        }
        title={"Trusted Cleaning Services in Atlanta,GA & Louisville, KY"}
        description={
          "At Tower Cleaning Services, we are dedicated to providing exceptional cleaning services to both commercial and residential clients. We are proud to serve the city of Louisville and the surrounding areas with our eco-friendly and health-conscious cleaning solutions. Our team of expert cleaners is highly trained, fully insured, and always ready to tackle any cleaning challenge you may have."
        }
        link={"/contactUs"}
        button={"Contact Us"}
        picture={
          "https://images.unsplash.com/photo-1646980241033-cd7abda2ee88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        }
        color={"#f5f5f5"}
      />
      <RequestQuote />
      <PicRight
        className="font-bold"
        subtitle={
          "Customized Cleaning Plans for Commercial and Residential Spaces"
        }
        title={"Premier Cleaning Services in Lexington, KY"}
        description={
          "If you are in need of top-quality cleaning services in Lexington, Bowling Green, or Cincinnati, look no further than Tower Cleaning Services. We are committed to delivering thorough and effective cleaning solutions that meet the unique needs of each of our clients. Whether you need regular cleaning services for your office or a one-time deep clean for your home, we have got you covered."
        }
        link={"/contactUs"}
        button={"Contact Us"}
        picture={
          "https://images.unsplash.com/photo-1587151587880-29db8f6c647a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
        }
        color={"#f5f5f5"}
      />
      <PicLeft
        subtitle={
          "Affordable and Efficient Cleaning Solutions for Homes and Offices"
        }
        title={"Top-Quality Cleaning Services in Nashville, TN"}
        description={
          "Tower Cleaning Services is proud to extend our exceptional cleaning services to the cities of Nashville and Indianapolis. Our team of experienced cleaners is dedicated to using environmentally friendly and health-conscious cleaning products to ensure that your home or office is clean, healthy, and safe. Contact us today to schedule a cleaning service that is tailored to your unique needs and budget."
        }
        link={"/contactUs"}
        button={"Contact Us"}
        picture={
          "https://plus.unsplash.com/premium_photo-1678304224445-df91815d5e18?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        }
        color={"#f5f5f5"}
      />
            <PicRight
        subtitle={
          "Comprehensive Eco-Friendly Cleaning Solutions for Homes and Businesses"
        }
        title={"The Premier Cleaning Service Atlanta, GA"}
        description={
          "At Tower Cleaning Services, we are dedicated to providing exceptional cleaning services to both commercial and residential clients. We are proud to serve the city of Atlanta and the surrounding areas with our eco-friendly and health-conscious cleaning solutions. Our team of expert cleaners is highly trained, fully insured, and always ready to tackle any cleaning challenge you may have."
        }
        link={"/contactUs"}
        button={"Contact Us"}
        picture={
          "https://images.unsplash.com/photo-1646980241033-cd7abda2ee88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        }
        color={"#f5f5f5"}
      />
      <RequestQuote />
      <PicRight />
    </div>
  );
};

export default Locations;

import IndustryTypes from "../IndustryTypes";
import Hero from "../Hero";
import Includes from "../Includes";
import MoreInfoService from "../MoreInfoService";
import RequestQuote from "../RequestQuote";
import PicRight from "../PicRight";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import School from "../../images/Schools.jpg"

const Educational = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = new URLSearchParams(location.search).get("title");
  const subtitle = new URLSearchParams(location.search).get("subtitle");
  const image = new URLSearchParams(location.search).get("image");
  const description = new URLSearchParams(location.search).get("description");

  const cards = [
    {
      name: "Why our School Cleaning Services? ",
      description: 
      "Cleaning schools requires attention to detail and a comprehensive approach to ensure a safe and healthy environment for students and staff. Our cleaning checklist includes dusting, wiping, and disinfecting all equipment, floor washing and maintenance, bathroom disinfecting, odor removal, entrance and glass cleaning, shower cleaning and sanitization, mold and mildew removal, and trash disposal. With our professional cleaning services, you can trust that your school will be thoroughly cleaned and maintained to the highest standards."
    },
      {
      name: "Covid Cleaning for Educational Facilities",
      description:
      "The COVID-19 pandemic has made it crucial for schools to implement strict cleaning protocols to ensure the safety of students and staff. At Tower Cleaning Services, we understand the importance of creating a clean and safe environment for students and staff to learn and work in. That's why we provide specialized COVID-19 cleaning services for schools that follow the guidelines set by the Centers for Disease Control and Prevention (CDC) and local health authorities.Our cleaning professionals are equipped with the latest cleaning products, equipment, and techniques to effectively sanitize and disinfect high-touch surfaces, classrooms, hallways, and common areas. We use EPA-approved disinfectants that are effective against the COVID-19 virus, and we ensure that all surfaces are thoroughly cleaned and disinfected to reduce the risk of transmission."
    },
  ];

  return (
    <div>
      <Hero
        title={title}
        subtitle={subtitle}
        button={"Call to Book →"}
        link={"/"}
        picture={School}
      />
      <PicRight
        subtitle={
          "Healthy Learning Environments with Customized Cleaning Plans"
        }
        title={"Educational Cleaning Plans"}
        description={`We offer expert cleaning solutions for educational institutions, customized to meet your specific requirements and exceed your expectations. Our experienced team of professionals utilizes the latest tools and techniques to deliver a spotless, sanitized environment that promotes the health and safety of students, staff, and visitors.\n\nFrom classrooms and labs to cafeterias and athletic facilities, we provide daily, weekly, and monthly cleaning services to meet your needs and budget. Contact us today to learn more about our cleaning solutions designed to meet the unique needs of schools, universities, and other educational facilities.`}
        button={"Schedule a Walkthrough"}
        picture={
          "https://images.unsplash.com/photo-1583947581924-860bda6a26df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1167&q=80"
        }
        color={"#f5f5f5"}
      />
      <Includes />
      <>
      <div className="relative isolate overflow-hidden py-10 ">
        <svg
          viewBox="0 0 1097 845"
          aria-hidden="true"
          className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
        >
          <path
            fill="url(#7c63f5ae-130c-4c0f-963f-50ac7fe8d2e1)"
            fillOpacity=".2"
            d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
          />
        </svg>
        <div className="mx-auto px-6 lg:px-32">
          <motion.h2
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="mb-5 text-3xl font-base tracking-wide text-[#900c3e] sm:text-6xl"
          >
            Choosing School Cleaning Services
          </motion.h2>
          <div className="mx-auto max-w-2xl lg:mx-0"></div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 bg-[#f5f5f5] gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:gap-1">
            {cards.map((card) => (
              <div key={card.name} className="justify-center flex gap-x-4] p-6">
                <div className="text-base leading-7 ">
                  <motion.h3
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.6 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="font-bold text-black hover:text-[#82123f]"
                  >
                    {card.name}
                  </motion.h3>
                  <motion.p
                    id="faqText"
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.7 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="mt-2  text-[#474d53] whitespace-pre-line"
                  >
                    {card.description}
                  </motion.p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <motion.div
        initial={{ scale: 0 }}
        transition={{ ease: "easeOut", duration: 0.8 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        className="text-center mb-10"
      >
      </motion.div>
    </>
      <RequestQuote />
    </div>
  );
};

export default Educational;

import IndustryTypes from "../IndustryTypes";
import Hero from "../Hero";
import Includes from "../Includes";
import MoreInfoService from "../MoreInfoService";
import RequestQuote from "../RequestQuote";
import PicRight from "../PicRight";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReligionImage from "../../images/Religion.jpg"
import { motion } from "framer-motion";

const Church = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = new URLSearchParams(location.search).get("title");
  const subtitle = new URLSearchParams(location.search).get("subtitle");
  const image = new URLSearchParams(location.search).get("image");
  const description = new URLSearchParams(location.search).get("description");

  const cards = [
    {
      name: "Why Us? ",
      description: "At Tower Cleaning Services, we take pride in our ability to provide exceptional cleaning services to religious facilities. With over a decade of experience, we understand the unique needs of these facilities and work closely with the management to develop customized cleaning plans. We have a team of skilled professionals who are trained to handle delicate items such as religious statues and artifacts, ensuring that they are cleaned and maintained properly. We use eco-friendly cleaning products that are safe for the environment and do not damage the surfaces. Our team also uses the latest cleaning equipment and technology to ensure that the cleaning process is efficient and effective. We use high-powered vacuums and steam cleaners to remove dirt and grime from hard-to-reach areas, and HEPA filters to remove allergens and pollutants from the air. We understand that religious facilities have busy schedules and require minimal disruption during the cleaning process. That's why we provide flexible cleaning schedules that are convenient for the facility. We work with the management to develop a cleaning schedule that meets the needs and budget of the facility.  At Tower Cleaning Services, we believe that a clean and hygienic religious facility promotes a sense of spiritual well-being among the worshippers. That's why we strive to create a welcoming environment that fosters peace and tranquility. Our proven track record of exceptional service has made us the top choice for religious facility cleaning needs. If you're looking for a professional cleaning company that understands the unique needs of religious facilities, look no further than Tower Cleaning Services."
    }
    ];


  return (
    <div>
      <Hero
        title={title}
        subtitle={subtitle}
        button={"Call to Book →"}
        link={"/"}
        picture={ReligionImage}
      />
      <PicRight
        subtitle={
          "Customized Plans for Spotless and Sanitized Worship Spaces, Classrooms, and More"
        }
        title={"Cleaning for Religious Facilities"}
        description={`Tower Cleaning Services offers tailored cleaning solutions for religious and church facilities. Our experienced team of cleaning professionals understands the unique requirements of your facility, from sanctuaries to classrooms and kitchens. We provide spotless and sanitized environments to promote the health and well-being of your congregation and visitors.\n\nOur customized cleaning plans ensure that every corner of your facility receives the attention it needs, exceeding your expectations. Whether you need weekly or monthly cleaning services, we work with you to create a plan that meets your needs and budget. Contact us today to learn more about our expert cleaning solutions for religious and church facilities.`}
        button={"Call for a Quote"}
        picture={
          "https://images.unsplash.com/photo-1583947581924-860bda6a26df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1167&q=80"
        }
        color={"#f5f5f5"}
      />
      <Includes />
      <>
      <div className="relative isolate overflow-hidden py-10 ">
        <svg
          viewBox="0 0 1097 845"
          aria-hidden="true"
          className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
        >
          <path
            fill="url(#7c63f5ae-130c-4c0f-963f-50ac7fe8d2e1)"
            fillOpacity=".2"
            d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
          />
        </svg>
        <div className="mx-auto px-6 lg:px-32">
          <motion.h2
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="mb-5 text-3xl font-base tracking-wide text-[#900c3e] sm:text-6xl"
          >
            Why choose us for Religious Cleaning Services
          </motion.h2>
          <div className="mx-auto max-w-2xl lg:mx-0"></div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 bg-[#f5f5f5] gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:gap-1">
            {cards.map((card) => (
              <div key={card.name} className="justify-center flex gap-x-4] p-6">
                <div className="text-base leading-7 ">
                  <motion.h3
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.6 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="font-bold text-black hover:text-[#82123f]"
                  >
                    {card.name}
                  </motion.h3>
                  <motion.p
                    id="faqText"
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.7 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="mt-2  text-[#474d53] whitespace-pre-line"
                  >
                    {card.description}
                  </motion.p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <motion.div
        initial={{ scale: 0 }}
        transition={{ ease: "easeOut", duration: 0.8 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        className="text-center mb-10"
      >
      </motion.div>
    </>
      <RequestQuote />
    </div>
  );
};

export default Church;

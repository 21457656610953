import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function FlyOutMenu({ name, menus, link, setMobileMenuOpen }) {
  const navigate = useNavigate();

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleClick = (href) => {
    setMobileMenuOpen(false);
    navigate(href);
  };
  return (
    <Popover className="relative">
      <Popover.Button
        onMouseEnter={() => setDropdownVisible(true)}
        onMouseLeave={() => setDropdownVisible(false)}
        className="inline-flex items-center gap-x-1 text-xl font-light leading-6 text-[#474d53] hover:text-[#900c3e]"
      >
        <>
          <button onClick={() => handleClick(link)}>
            <span>{name}</span>
          </button>
          {name === "About Us" ? null : (
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          )}
        </>
      </Popover.Button>

      {name !== "About Us" && (
        <Transition
          as={Fragment}
          show={dropdownVisible}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 -translate-y-2"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          {menus && (
            <Popover.Panel
              onMouseEnter={() => setDropdownVisible(true)}
              onMouseLeave={() => setDropdownVisible(false)}
              className="absolute left-1/2 z-10 mt-4 flex w-screen max-w-min -translate-x-1/2 px-4"
            >
              <div className="rounded-md w-60 shrink bg-white p-4 text-md leading-6 text-[#474d53] shadow-lg ring-1 ring-gray-900/5">
                {menus.map((item) => (
                  <button
                    key={item.name}
                    onClick={() => handleClick(item.href)}
                    className="block p-1.5 mb-1 hover:text-[#900c3e] hover:underline text-left"
                  >
                    {item.name}
                  </button>
                ))}
              </div>
            </Popover.Panel>
          )}
        </Transition>
      )}
    </Popover>
  );
}

import IndustryTypes from "../components/IndustryTypes";
import Hero from "../components/Hero";
import Includes from "../components/Includes";
import MoreInfoService from "../components/MoreInfoService";
import RequestQuote from "../components/RequestQuote";
// import IndustryPromos from "../components/IndustryPromos"
import { useEffect } from "react";
import PicRight from "../components/PicRight";
import Facilities from "../images/Facilities.jpg"

const Industries = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero
        title={"Industries"}
        subtitle={
          "Comprehensive Cleaning Solutions for All Industries, From Corporate Offices to Healthcare Facilities"
        }
        button={"Call for a Quote"}
        link={"/"}
        picture={Facilities}
      />
      <PicRight
        subtitle={"Award winning support"}
        title={"We’re here to help"}
        description={
          "Discover Tower Cleaning Services and our dedication to eco-friendly, health-conscious cleaning for all industries. We provide all-encompassing green janitorial solutions, creating a pristine and welcoming atmosphere for businesses, clients, and residents alike."
        }
        link={"/contactUs"}
        button={"Contact Us"}
        picture={
          "https://images.unsplash.com/photo-1626573500445-476005ab7b90?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        }
        color={"#f5f5f5"}
      />
      {/* <IndustryPromos
        subtitle={"Award winning support"}
        title={"We’re here to help"}
        description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et, egesta tempus tellus etiam sed. "}
        number={"(502) 309-2550"}
        contact={"Contact Us Now!"}
        picture={"https://images.unsplash.com/photo-1601160458000-2b11f9fa1a0e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"}
        color={"#f5f5f5"}
      /> */}
      <IndustryTypes />
      <Includes />
      <RequestQuote />
    </div>
  );
};

export default Industries;

import OurServices from "../components/OurServices";
import Hero from "../components/Hero";
import PicLeft from "../components/PicLeft";
import RequestQuote from "../components/RequestQuote";
import PicRight from "../components/PicRight";


const LocationTypes = ({ data }) => {
  console.log(data)
  return (
    <div>
      <Hero
        title={data.heroTitle}
        subtitle={data.heroSubtitle}
        button={"Book Now!"}
        link={data.city === "Atlanta" ? "/services/residential/atlanta/booking" : "/services/residential/booking"}
        picture={data.picture[data.city]}
      />
      <PicRight
        subtitle={data.subtitle1}
        title={data.title1}
        description={data.description1}
        button={"Learn More About Us"}
        picture={
          "https://images.unsplash.com/photo-1646980241033-cd7abda2ee88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        }
        color={"#f5f5f5"}
      />
      <RequestQuote />
      <PicRight
        subtitle={data.subtitle2}
        title={data.title2}
        description={data.description2}
        button={"Book Now!"}
        picture={
          "https://images.unsplash.com/photo-1587151587880-29db8f6c647a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
        }
        color={"#f5f5f5"}
      />
      <PicLeft
        subtitle={data.subtitle3}
        title={data.title3}
        description={data.description3}
        button={"Call for a Quote!"}
        picture={
          "https://plus.unsplash.com/premium_photo-1678304224445-df91815d5e18?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        }
        color={"#f5f5f5"}
      />
    </div>
  );
};

export default LocationTypes;

import IndustryTypes from "../IndustryTypes";
import Hero from "../Hero";
import Includes from "../Includes";
import MoreInfoService from "../MoreInfoService";
import RequestQuote from "../RequestQuote";
import PicRight from "../PicRight";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import hotels from "../../images/Hotels.jpg"
import { motion } from "framer-motion";

const Restaurant = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = new URLSearchParams(location.search).get("title");
  const subtitle = new URLSearchParams(location.search).get("subtitle");
  const image = new URLSearchParams(location.search).get("image");
  const description = new URLSearchParams(location.search).get("description");

  const cards = [
  {
    name: "Why Us?",
    description: "Tower Cleaning Services is the best cleaning service for hotels because we have a large pool of vetted and experienced cleaners available at a moment's notice. We understand that hotels operate on a tight schedule and may need cleaning services outside of regular business hours or in emergencies. Our team is flexible and can accommodate last-minute requests to ensure that your hotel is always clean and presentable for your guests. We also use the latest cleaning techniques and equipment to provide efficient and thorough cleaning services. Our commitment to quality and attention to detail make us the ideal cleaning partner for any hotel looking to maintain a high standard of cleanliness and guest satisfaction."
  },
    {
      name: "Hospitality DIY Cleaning Checklist",
      description: "When cleaning a hotel, it's important to take a thorough and detailed approach to ensure that all areas are cleaned effectively. This includes pre-cleaning by removing any trash or debris, stripping and making beds, cleaning the bathroom, dusting and wiping down surfaces, vacuuming and mopping floors, restocking supplies, cleaning common areas, and sanitizing high-touch areas. By following these steps, a hotel can maintain a clean and inviting environment for guests, while also ensuring the health and safety of both guests and employees. Regular cleaning and disinfection are essential to keep a hotel running smoothly and maintain a positive reputation."
    },
    ];

  return (
    <div>
      <Hero
        title={title}
        subtitle={subtitle}
        button={"Call to Book →"}
        link={"/"}
        picture={hotels}
      />
      <PicRight
        subtitle={
          "Keep Your Customers Safe and Satisfied with Our Tailored Cleaning Plans"
        }
        title={"Cleaning Solutions for Restaurants"}
        description={`At Tower Cleaning Services, we provide expert cleaning solutions for restaurants and hospitality spaces. Our tailored cleaning plans deliver a spotless, hygienic environment that promotes food safety and customer satisfaction. From dining areas and kitchen equipment to restrooms and break rooms, we customize our cleaning plans to meet your needs and budget.\n\nOur experienced team of cleaning professionals is equipped with the latest tools and techniques to exceed your expectations. Contact us today to learn more about our cleaning solutions for restaurant and hospitality spaces.`}
        link={"/contactUs"}
        button={"Contact Us"}
        picture={
          "https://washh.com/wp-content/uploads/2020/03/restaurant-cleaning-checklist.jpeg"
        }
        color={"#f5f5f5"}
      />
      <Includes />
      <>
      <div className="relative isolate overflow-hidden py-10 ">
        <svg
          viewBox="0 0 1097 845"
          aria-hidden="true"
          className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
        >
          <path
            fill="url(#7c63f5ae-130c-4c0f-963f-50ac7fe8d2e1)"
            fillOpacity=".2"
            d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
          />
        </svg>
        <div className="mx-auto px-6 lg:px-32">
          <motion.h2
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="mb-5 text-3xl font-base tracking-wide text-[#900c3e] sm:text-6xl"
          >
            Why choose us for Hospitality Cleaning Services
          </motion.h2>
          <div className="mx-auto max-w-2xl lg:mx-0"></div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 bg-[#f5f5f5] gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:gap-1">
            {cards.map((card) => (
              <div key={card.name} className="justify-center flex gap-x-4] p-6">
                <div className="text-base leading-7 ">
                  <motion.h3
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.6 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="font-bold text-black hover:text-[#82123f]"
                  >
                    {card.name}
                  </motion.h3>
                  <motion.p
                    id="faqText"
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.7 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="mt-2  text-[#474d53] whitespace-pre-line"
                  >
                    {card.description}
                  </motion.p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <motion.div
        initial={{ scale: 0 }}
        transition={{ ease: "easeOut", duration: 0.8 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        className="text-center mb-10"
      >
      </motion.div>
    </>
      <RequestQuote />
    </div>
  );
};

export default Restaurant;

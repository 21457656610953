import IndustryTypes from "../IndustryTypes";
import Hero from "../Hero";
import Includes from "../Includes";
import MoreInfoService from "../MoreInfoService";
import RequestQuote from "../RequestQuote";
import PicRight from "../PicRight";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CoWorking from "../../images/CoWorking.jpg"
import { motion } from "framer-motion";

const CoWork = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = new URLSearchParams(location.search).get("title");
  const subtitle = new URLSearchParams(location.search).get("subtitle");
  const image = new URLSearchParams(location.search).get("image");
  const description = new URLSearchParams(location.search).get("description");
  
  const cards = [
    {
      name: "Why Us?",
      description: "Tower Cleaning Services is the best choice for coworking cleaning services due to our commitment to providing a comprehensive and customized cleaning solution for each client. We understand that coworking spaces have unique cleaning needs, and we have the expertise to address them. Our team of highly trained and professional cleaners uses the latest cleaning equipment and non-toxic, eco-friendly cleaning solutions to ensure the health and safety of all coworkers. We pay special attention to high-touch areas such as door handles, light switches, and shared equipment to minimize the risk of germs spreading. We also offer flexible scheduling options to minimize disruption to the workspace. At Tower Cleaning Services, we prioritize our clients' satisfaction, and our goal is to create a clean, healthy, and inviting workspace for all coworkers."
    },
    {
      name: "Co-working DIY Cleaning Checklist",
      description: "Cleaning a coworking space involves several steps to ensure that it is a safe and inviting environment for its users. The first step is to declutter the space by removing any trash and wiping down surfaces with a microfiber cloth to eliminate dust and debris. It is essential to vacuum or sweep the floors thoroughly, including under desks and in corners, to get rid of dirt and dust. High-touch areas such as doorknobs, light switches, keyboards, and shared equipment should be disinfected using a disinfectant spray or wipe. Cleaning and sanitizing the bathrooms, including the toilets, sinks, and mirrors, is crucial, and soap and paper towel dispensers should be refilled. Windows should be cleaned with a glass cleaner to ensure that they are streak-free, and the space should be mopped with a disinfectant solution to eliminate remaining dirt and bacteria. It is also necessary to empty and clean the kitchen sink and wipe down the counters and appliances. Lastly, checking the air filters and vents and replacing them if needed ensures that the air quality is clean and healthy. By following these steps, a coworking space can be kept clean and hygienic for all its users."
    }
    ];

  return (
    <div>
      <Hero
        title={title}
        subtitle={subtitle}
        button={"Call to Book →"}
        link={"/"}
        picture={CoWorking}
      />
      <PicRight
        subtitle={
          "Clean and Professional Environments that Promote Collaboration and Productivity"
        }
        title={"Elevate Your Co-Working Space with Expert Cleaning Solutions"}
        description={`Tower Cleaning Services offers expert cleaning solutions for co-working and hybrid office spaces. Our team of professionals uses state-of-the-art tools and techniques to create a clean, sanitized environment that promotes productivity, collaboration, and the health of your staff and members.\n\nWe customize our cleaning plans to fit your specific needs and budget, whether you require daily, weekly, or monthly cleaning services. Contact us today to learn more about our expert cleaning solutions.`}
        button={"Call for a Quote"}
        picture={
          "https://images.unsplash.com/photo-1583947581924-860bda6a26df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1167&q=80"
        }
        color={"#f5f5f5"}
      />
      <Includes />
      <>
      <div className="relative isolate overflow-hidden py-10 ">
        <svg
          viewBox="0 0 1097 845"
          aria-hidden="true"
          className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
        >
          <path
            fill="url(#7c63f5ae-130c-4c0f-963f-50ac7fe8d2e1)"
            fillOpacity=".2"
            d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
          />
        </svg>
        <div className="mx-auto px-6 lg:px-32">
          <motion.h2
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="mb-5 text-3xl font-base tracking-wide text-[#900c3e] sm:text-6xl"
          >
            Why choose us for Co-Working Cleaning Services
          </motion.h2>
          <div className="mx-auto max-w-2xl lg:mx-0"></div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 bg-[#f5f5f5] gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:gap-1">
            {cards.map((card) => (
              <div key={card.name} className="justify-center flex gap-x-4] p-6">
                <div className="text-base leading-7 ">
                  <motion.h3
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.6 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="font-bold text-black hover:text-[#82123f]"
                  >
                    {card.name}
                  </motion.h3>
                  <motion.p
                    id="faqText"
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.7 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="mt-2  text-[#474d53] whitespace-pre-line"
                  >
                    {card.description}
                  </motion.p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <motion.div
        initial={{ scale: 0 }}
        transition={{ ease: "easeOut", duration: 0.8 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        className="text-center mb-10"
      >
      </motion.div>
    </>
      <RequestQuote />
    </div>
  );
};

export default CoWork;

import Hero from "../components/Hero";
import Includes from "../components/Includes";
import MoreInfoService from "../components/MoreInfoService";
import PicLeft from "../components/PicLeft";
import RequestQuote from "../components/RequestQuote";
import PicRight from "../components/PicRight";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";



const Commercial = () => {
    const location = useLocation();
  
    const title = new URLSearchParams(location.search).get("title");
    const subtitle = new URLSearchParams(location.search).get("subtitle");
    const image = new URLSearchParams(location.search).get("image");
    const description = new URLSearchParams(location.search).get("description");
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const cards = [
      {
        name: "Why Tower Cleaning Services",
        description:
"We pride ourselves on delivering exceptional customer service. Our team is committed to open and honest communication, with fast turnaround times for emails, quotes, and phone calls. We understand the importance of being responsive and reliable, which is why we always strive to be available when you need us.Our team of professionals is fully insured and vetted, giving you peace of mind knowing that your commercial property is in good hands. We go above and beyond to ensure that our team is trained and equipped to deliver top-quality cleaning services that meet your unique needs.Additionally, we offer a 100% satisfaction guarantee for all of our cleaning services. If for any reason you're not completely satisfied with our work, simply let us know, and we'll make it right."      },
      {
        name: "Locations Served",
        description:
        "Tower Cleaning Services is proud to offer commercial cleaning services to a variety of locations throughout the Midwest and Southeast. Our services are available in major cities such as Louisville, Nashville, Lexington, Bowling Green, Cincinnati, and Indianapolis, as well as many other surrounding areas. Whether you have a small office or a large commercial property, we can provide you with customized solutions to meet your specific cleaning needs. Our team of trained professionals is equipped with specialized equipment and high-quality cleaning products to ensure that your space is clean, safe, and welcoming. We take pride in serving our clients and strive to provide exceptional customer service and satisfaction. With Tower Cleaning Services, you can trust that your commercial property is in good hands."
      },
    ];
  
    return (
      <div>
        <Hero
          title={title}
          subtitle={"Elevate Your Business With Eco-Friendly Cleaning Services."}
          button={"Get a Quote"}
          link={"/ContactUs"}
          picture={
            "https://images.unsplash.com/photo-1482449609509-eae2a7ea42b7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          }
        />
        <PicRight
          subtitle={"Eco-Friendly Cleaning for Homes and Businesses"}
          title={"We’re here to help"}
          description={
            "Experience the difference with Tower Cleaning Services, specializing in eco-friendly, health-conscious cleaning solutions for commercial and residential settings. Based in Atlanta,GA & Louisville, KY, we deliver all-encompassing green janitorial services, creating a healthier, welcoming ambiance for businesses, clients, and residents."
          }
          button={"Get in touch"} 
          link={"/ContactUs"}
          picture={
            "https://images.unsplash.com/photo-1581578949510-fa7315c4c350?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
          }
          color={"#f5f5f5"}
        />
        <Includes />
        <>
      <div className="relative isolate overflow-hidden py-10 ">
        <svg
          viewBox="0 0 1097 845"
          aria-hidden="true"
          className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
        >
          <path
            fill="url(#7c63f5ae-130c-4c0f-963f-50ac7fe8d2e1)"
            fillOpacity=".2"
            d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
          />
        </svg>
        <div className="mx-auto px-6 lg:px-32">
          <motion.h2
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="mb-5 text-3xl font-base tracking-wide text-[#900c3e] sm:text-6xl"
          >
            Commercial Cleaning Services
          </motion.h2>
          <div className="mx-auto max-w-2xl lg:mx-0"></div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 bg-[#f5f5f5] gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:gap-1">
            {cards.map((card) => (
              <div key={card.name} className="justify-center flex gap-x-4] p-6">
                <div className="text-base leading-7 ">
                  <motion.h3
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.6 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="font-bold text-black hover:text-[#82123f]"
                  >
                    {card.name}
                  </motion.h3>
                  <motion.p
                    id="faqText"
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.7 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="mt-2  text-[#474d53] whitespace-pre-line"
                  >
                    {card.description}
                  </motion.p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <motion.div
        initial={{ scale: 0 }}
        transition={{ ease: "easeOut", duration: 0.8 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        className="text-center mb-10"
      >
      
      </motion.div>
    </>
        <PicLeft
          title={"Floor Care"}
          description={
            "At Tower Cleaning Services, we offer comprehensive floor care cleaning services to keep your commercial property's flooring looking its best. Our team of trained professionals uses specialized equipment and high-quality cleaning products to ensure that each type of flooring is thoroughly cleaned and maintained. Whether you have carpet, tile, hardwood, or concrete flooring, we can provide customized solutions to meet your specific needs. Our floor care cleaning services include sweeping, mopping, stripping, waxing, and buffing, among others, to ensure that your floors are clean, safe, and long-lasting. With our regular floor care services, you can save money on costly repairs or replacements and maintain a professional and hygienic environment for your employees and customers."
          }
          button={"Get in touch"}
          link={"/ContactUs"}
          picture={
            "https://plus.unsplash.com/premium_photo-1678304224606-b68796c4e0ef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          }
          color={"#f5f5f5"}
        />
        <PicRight
          title={"Deep Cleaning"}
          description={
            "Experience the difference with Tower Cleaning Services, specializing in eco-friendly, health-conscious cleaning solutions for commercial and residential settings. Based in Atlanta, GA & Louisville, KY, we deliver all-encompassing green janitorial services, creating a healthier, welcoming ambiance for businesses, clients, and residents."
          }
          button={"Get in touch"}
          link={"/ContactUs"}
          picture={
            "https://images.unsplash.com/photo-1582816380203-03126488757f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
          }
          color={"#f5f5f5"}
        />
        <PicLeft
          title={"Upholstery Care"}
          description={
"Our team of trained professionals uses specialized equipment and eco-friendly cleaning products to deep clean and restore your upholstery. Our upholstery care services include stain removal, odor removal, and fabric protection to keep your furniture looking clean and fresh. We understand the importance of maintaining a clean and professional appearance for your business, which is why we provide personalized solutions tailored to your specific needs. Whether you have a small office or a large retail space, we can help you maintain the cleanliness and comfort of your upholstery. With our upholstery care services, you can create a welcoming and hygienic environment for your employees and customers alike."          }
          button={"Get in touch"}
          link={"/ContactUs"}
          picture={
            "https://plus.unsplash.com/premium_photo-1678718606493-9acf0fb85e1b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80"
          }
          color={"#f5f5f5"}
        />
        <PicRight
          title={"Outdoor Maintenance"}
          description={
"At Tower Cleaning Services, we understand the importance of maintaining a clean and safe outdoor environment for your commercial property. That's why we offer a variety of outdoor maintenance commercial cleaning services to suit your needs. Our services include graffiti removal, trash collection, window cleaning, and parking lot cleaning, among others. Our team of skilled professionals uses state-of-the-art equipment and eco-friendly cleaning products to ensure that your property's exterior is spotless and well-maintained. Whether you need a one-time service or regular maintenance, we can provide you with customized solutions to meet your specific needs. With our outdoor maintenance services, you can rest assured that your property's exterior will always look its best."          }
          button={"Get in touch"}
          link={"/ContactUs"}
          picture={
            "https://images.unsplash.com/photo-1582816380203-03126488757f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
          }
          color={"#f5f5f5"}
        />
         <PicLeft
         className = "ml-16"
          title={"Disinfection Services"}
          description={
"Tower Cleaning Services is committed to providing effective disinfection services to our clients. We utilize a variety of methods, including fogging and misting, electrostatic spraying, UV-C disinfection, and antimicrobial coatings, to ensure that surfaces are thoroughly disinfected and free from harmful microorganisms. Our team of trained professionals is equipped with specialized equipment and high-quality cleaning products to provide top-notch disinfection services. Whether you have a large warehouse or a healthcare facility, we can help you create a safe and healthy environment for your employees and customers."          }
          button={"Get in touch"}
          link={"/ContactUs"}
          picture={
            "https://plus.unsplash.com/premium_photo-1678718606493-9acf0fb85e1b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80"
          }
          color={"#f5f5f5"}
        />
         <PicRight
          title={"Carpet Care"}
          description={
            "Tower Cleaning Services provides hot water extraction (Steam Cleaning) and Dry Cleaning Carpet Care solutions depending on the carpet and the client needs. Our hot water extraction includes a pre-treatment solution which is applied to the carpet to loosen dirt and stains. Next, hot water is injected into the carpet at high pressure, which helps to break up dirt and contaminants. Finally, a powerful vacuum is used to extract the water, along with the dirt and cleaning solution, from the carpet. Our Dry Cleaning solutions does not use waterfor clients who may need quicker carpet cleaning needs and where the risk of mold is high. The service includes a cleaning agent which is applied to the carpet to loosen dirt and stains followed by a specialized machine to extract the cleaning agent and dirt from the carpet. Reach out to us to get more tailored instruction and help."
          }
          button={"Get in touch"}
          link={"/ContactUs"}
          picture={
            "https://images.unsplash.com/photo-1582816380203-03126488757f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
          }
          color={"#f5f5f5"}
        />
        <RequestQuote />
      </div>
    );
  };
  
  export default Commercial;
  
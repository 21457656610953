import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";
import { useState } from "react";
import FlyOutMenu from "./FlyOutMenu";
import { GiVacuumCleaner } from "react-icons/gi";
import logo from "../images/towermaidslogo.png";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const industries = [
    {
      name: "Office and Commercial Property Cleanings",
      href: "/industries/offices-buildings?title=Offices and Buildings&subtitle=Expert Cleaning Solutions for All Types of Corporate Spaces&image=url",
    },
    {
      name: "Medical and Healthcare Facility Cleanings",
      href: "/industries/medical-facilities?title=Medical Facilities&subtitle=Cleaning Services for a Healthy, Sanitized Environment&image=url",
    },
    {
      name: "Industrial Facility Cleanings",
      href: "/industries/industrial-manufacturing?title=Industrial / Manufacturing&subtitle=Advanced Cleaning Solutions for Industrial Settings&image=url",
    },
    {
      name: "Educational Institutions Cleanings",
      href: "/industries/education-schools?title=Education / Schools&subtitle=Comprehensive Cleaning for Educational Facilities&image=url",
    },
    {
      name: "Banks and Financial Institutions Cleanings",
      href: "/industries/banks-financial?title=Banks / Financial&subtitle=Impeccable Cleaning for Banks and Financial Institutions&image=url",
    },
    {
      name: "Government Building Cleanings",
      href: "/industries/government?title=Government Cleaning&subtitle=Trusted Cleaning Services for Government Buildings&image=url",
    },
    {
      name: "Church and Religious Facility Cleanings",
      href: "/industries/religious?title=Religious Facilities Cleaning&subtitle=Customized Cleaning Solutions for Religious Institutions&image=url",
    },
    {
      name: "Health Club Cleanings",
      href: "/industries/health-clubs?title=Health Clubs / Gyms&subtitle=Specialized Cleaning for Fitness Centers, Health Clubs, and Gyms&image=url",
    },
    {
      name: "Co-Working/Hybrid Offices",
      href: "/industries/events?title=Co-Working / Hybrid Offices&subtitle=Flexible Cleaning Services for Co-Working Spaces&image=url",
    },
    {
      name: "Retail and Store Cleanings",
      href: "/industries/retail?title=Retail Spaces / Stores&subtitle=Exceptional Cleaning for a Sparkling, Inviting Retail Environment&image=url",
    },
    {
      name: "Restaurant and Hospitality Cleanings",
      href: "/industries/restaurant-hospitality?title=Restaurant / Hospitality&subtitle=Meticulous Cleaning for Restaurants and Hospitality Settings&image=url",
    },
    {
      name: "Gas Station Cleanings",
      href: "/industries/gas-station?title=Gas Station&subtitle=Quality Cleaning for a Pristine and Safe Gas Station Environment&image=url",
    },
  ];

  const locations = [
    { name: "Louisville, KY", href: "/locations/louisville?title=Louisville" },
    {
      name: "Atlanta, GA",
      href: "/locations/Atlanta?title=Atlanta",
    },
  ];

  const services = [
    { name: "Commercial", href: "/services/commercial?title=Commercial" },
    { name: "Residential", href: "/services/residential?title=Residential" },
  ];

  const about = [];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigate = useNavigate();

  const handleNavigate = (link) => {
    setMobileMenuOpen(false);
    navigate(link);
  };

  return (
    <header className="bg-white h-18 w-full fixed top-10 z-40 shadow-md">
      <nav
        className="flex items-center justify-between px-1"
        aria-label="Global"
      >
        <div className="flex lg:flex-2">
          <Link to={"/"} className="m-1.5">
            <span className="sr-only">Tower Maids</span>
            <img
              src={logo}
              alt="/"
              className="h-16 p-2 w-auto mr-2 text-[#900c3e]"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="m-1.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-6 ml-4">
          <FlyOutMenu
            className="text-xl font-light leading-6 text-[#474d53] rounded-lg p-2"
            name={"About Us"}
            menus={about}
            link={"/about-us"}
            setMobileMenuOpen={setMobileMenuOpen}
          />

          <FlyOutMenu
            className="text-xl font-light leading-6 text-[#474d53] rounded-lg p-2"
            name={"Services"}
            menus={services}
            link={"/services"}
            setMobileMenuOpen={setMobileMenuOpen}
          />

          <FlyOutMenu
            className="text-s font-light leading-6 text-[#474d53] rounded-lg p-2"
            name={"Industries"}
            menus={industries}
            link={"/industries"}
            setMobileMenuOpen={setMobileMenuOpen}
          />

          <FlyOutMenu
            className="text-xl font-light leading-6 text-[#474d53] rounded-lg p-2"
            name={"Locations"}
            menus={locations}
            link={"/locations"}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Link
            to="tel:9124553605"
            className="text-xl font-light leading-6 text-[#474d53] hover:text-[#900c3e] p-2 rounded-lg"
          >
            (912) 455-3650 <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden z-50"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#f5f5f5] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to={"/"} className="-m-1.5 p-1.5">
              <span className="sr-only">Tower Maids</span>
              <img
                src={logo}
                alt=""
                className="h-12 w-auto mr-2 text-[#900c3e]"
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6 flex flex-col">
                <FlyOutMenu
                  name={"About us"}
                  menus={about}
                  link={"/about-us"}
                  setMobileMenuOpen={setMobileMenuOpen}
                />

                <FlyOutMenu
                  name={"Services"}
                  menus={services}
                  link={"/services"}
                  setMobileMenuOpen={setMobileMenuOpen}
                />

                <FlyOutMenu
                  name={"Industries"}
                  menus={industries}
                  link={"/industries"}
                  setMobileMenuOpen={setMobileMenuOpen}
                />

                <FlyOutMenu
                  name={"Locations"}
                  menus={locations}
                  link={"/locations"}
                  setMobileMenuOpen={setMobileMenuOpen}
                />
              </div>
              <div className="py-6">
                <Link
                  to="tel:9124553605"
                  className="-mx-3 block rounded-3xl py-2.5 px-3 text-base font-semibold leading-7 text-white hover:bg-[#82123f] bg-[#900c3e]"
                >
                  912-455-3605 <span aria-hidden="true">→</span>
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Nav;

import TextPicture from "../components/TextPicture";
import Mission from "../components/Mission";
import Hero from "../components/Hero";
import { useEffect } from "react";
import PicRight from "../components/PicRight";
import TextImageBox from "../components/TextImageBox";
import Faq from "../components/Faq";
import Image from "../images/CommercialCleaning.jpg"

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero
        title={"About Us"}
        subtitle={
          "Discover the Tower Difference – Impeccable Cleaning Solutions Tailored To Create Pristine, Inviting Spaces for Work and Home"
        }
        button={"Request a Quote"}
        link={"/ContactUs"}
        picture={Image}
      />
      <TextImageBox />
      <Mission />
      <PicRight
        subtitle={"Award winning support"}
        title={"We’re here to help"}
        description={
          "Tower Cleaning Services leads the way in eco-friendly and health-conscious commercial cleaning solutions. Based in Atlanta, GA & Louisville, KY, our innovative cleaning company offers businesses comprehensive green janitorial services, creating a healthier environment for your customers and employees."
        }
        button={"Call Us At (912) 455-3650"}
        picture={
          "https://images.unsplash.com/photo-1627905646269-7f034dcc5738?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8Y2xlYW5pbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
        }
        color={"#f5f5f5"}
      />
      <Faq />
    </div>
  );
};

export default AboutUs;

import IndustryTypes from "../IndustryTypes";
import Hero from "../Hero";
import Includes from "../Includes";
import RequestQuote from "../RequestQuote";
import PicRight from "../PicRight";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import OfficeBuilding from "../../images/Office-Building.jpg"

const Offices = () => {
  const location = useLocation();

  const cards = [
    {
      name: "What should you expect from an office cleaning?",
      description:
      "Office cleaning involves several essential tasks to maintain a clean and healthy workspace. A comprehensive checklist may include general cleaning such as commercial cleaning and janitorial services, hygiene maintenance such as surface disinfection and sanitation services, deep cleaning services like window cleaning and air duct cleaning, specific area cleaning like restroom cleaning and vacuuming, and organizational cleaning such as office organization and corporate cleaning. To ensure a thorough and effective cleaning, make sure to assign specific duties to your cleaning staff and regularly review and update the checklist based on your office's needs and requirements."
    },
    {
      name: "Why us",
      description: `Tower Cleaning Services is a professional cleaning company that provides exceptional service for high-rise buildings and offices. With a focus on reliability, efficiency, and attention to detail, our experienced professionals have the necessary equipment, training, and expertise to clean high-rise buildings safely and effectively. We pride ourselves on our communication skills and professionalism, ensuring that our clients' needs are met with customized cleaning solutions that are flexible and adaptable to changes in schedule or cleaning requirements. Our commitment to using the latest cleaning products, equipment, and techniques sets us apart from our competitors, and our dedication to delivering high-quality results in a safe and efficient manner makes us the perfect choice for all your cleaning needs.`
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = new URLSearchParams(location.search).get("title");
  const subtitle = new URLSearchParams(location.search).get("subtitle");
  const image = new URLSearchParams(location.search).get("image");
  const description = new URLSearchParams(location.search).get("description");

  return (
    <div>
      <Hero
        title={title}
        subtitle={subtitle}
        button={"Call to Book →"}
        link={"/"}
        picture={OfficeBuilding }
      />
      <PicRight
        subtitle={"Eco-Friendly Cleaning Solutions for Corporate Spaces"}
        title={"Transform Your Workspace"}
        description={
          "Elevate your corporate space with expert cleaning solutions from Tower Cleaning Services. Our eco-friendly and health-conscious cleaning services create a spotless, welcoming environment that's also healthy for your employees. From small offices to large buildings, our experienced team can customize a cleaning plan to meet your unique needs and budget. Contact us today to discover how we can help elevate your workspace."
        }
        link={"/contactUs"}
        button={"Contact Us"}
        picture={
          "https://images.unsplash.com/photo-1583947581924-860bda6a26df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1167&q=80"
        }
        color={"#f5f5f5"}
      />
      <Includes />
      <>
      <div className="relative isolate overflow-hidden py-10 ">
        <svg
          viewBox="0 0 1097 845"
          aria-hidden="true"
          className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
        >
          <path
            fill="url(#7c63f5ae-130c-4c0f-963f-50ac7fe8d2e1)"
            fillOpacity=".2"
            d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
          />
        </svg>
        <div className="mx-auto px-6 lg:px-32">
          <motion.h2
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="mb-5 text-3xl font-base tracking-wide text-[#900c3e] sm:text-6xl"
          >
            Choosing Office Cleaning Services
          </motion.h2>
          <div className="mx-auto max-w-2xl lg:mx-0"></div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 bg-[#f5f5f5] gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:gap-1">
            {cards.map((card) => (
              <div key={card.name} className="justify-center flex gap-x-4] p-6">
                <div className="text-base leading-7 ">
                  <motion.h3
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.6 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="font-bold text-black hover:text-[#82123f]"
                  >
                    {card.name}
                  </motion.h3>
                  <motion.p
                    id="faqText"
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.7 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="mt-2  text-[#474d53] whitespace-pre-line"
                  >
                    {card.description}
                  </motion.p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <motion.div
        initial={{ scale: 0 }}
        transition={{ ease: "easeOut", duration: 0.8 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        className="text-center mb-10"
      >
      </motion.div>
    </>
      <RequestQuote />
    </div>
  );
};

export default Offices;

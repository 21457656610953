import Hero from "../components/Hero";
import Includes from "../components/Includes";
import MoreInfoService from "../components/MoreInfoService";
import PicLeft from "../components/PicLeft";
import RequestQuote from "../components/RequestQuote";
import PicRight from "../components/PicRight";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import calendar  from "../images/Calendar.png";



const Residential = () => {
    const location = useLocation();
  
    const title = new URLSearchParams(location.search).get("title");
    const subtitle = new URLSearchParams(location.search).get("subtitle");
    const image = new URLSearchParams(location.search).get("image");
    const description = new URLSearchParams(location.search).get("description");
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const cards = [
      {
        name: "Should I hire someone to do my Louisville house cleaning?",
        description:
          "You may be wondering what is involved in doing a Louisville house cleaning or apartment cleaning. Whether you are planning to move out of an apartment or house or move into one, a thorough cleaning is necessary to come into or leave a place clean and tidy. When deciding whether to hire a maid service or housekeeper, it’s important to determine how much you are willing to spend on a home cleaning service and how much time it takes to complete a thorough cleaning. Please check out our estimator at the top of the page to see if the cost of cleaning makes sense for you. If you already determined that an insured house cleaning or office cleaning service is what you need, Tower Maids in Louisville is here to help with your home cleaning needs.",
      },
      {
        name: "How do I clean like the best Louisville house cleaners if I want to do it myself?",
        description: `If you are looking to take a do-it-yourself approach, it is best to act like a professional during your Louisville house cleaning. We have a checklist down below that we ensure will lead to a cleaner home whether you are looking for a deep clean to pass an inspection, a process to picking up the clutter in your house, or one for cleaning toilets and kitchens.Once you have gone through the checklist, do a walkthrough to make sure you cleaned everything. Some easy-to-forget areas are windows and doors and make sure to spend added time on the kitchen and bathroom to truly get the experience you would get from the best apartment cleaners in Louisville. Once you’ve treated your home to a wonderful cleaning experience, keep up a cleaning schedule, so you don’t allow areas to get too dirty or messy. If you decide that this is too much and want to hire the best house cleaners near Louisville, make sure you vet the maid companies to ensure they are reputable house cleaners. Tower Maids, operates in Louisville, and makes it very clear that we only have maids that are insured and that have undergone test clean(s) before going to a customer’s home. Points to consider when vetting house cleaners include making sure the apartment cleaning Louisville company or Louisville maid service are well-known and can do a home cleaning, apartment cleaning, or office cleaning with their own cleaning supplies. The best cleaning services in Louisville always carry their own cleaning supplies. Additionally, don’t be afraid to tell cleaning services that you want certain areas to have more attention than others. The best Louisville maid services always make sure that your cleaning needs exceed your expectations, so feel free to do a walkthrough inspection to make sure it is done the way you want. With Tower Maids, rest assured that our maids will always be respectful and treat your home as our own. `,
      },
      {
        name: "Unsure if professional house cleaning is the right choice for you?",
        description:
          "If you're uncertain whether to opt for professional house cleaning services, consider the benefits they offer. A professional cleaning company like Tower Cleaning Services provides an array of advantages, including expert-level cleaning expertise, access to specialized equipment and cleaning solutions, and customized cleaning plans that cater to your unique needs and preferences. Additionally, with the convenience of flexible scheduling and a reliable, efficient team of cleaning professionals, you can enjoy more free time to focus on the things that matter most to you. Ultimately, a professional cleaning service can make a significant difference in the cleanliness and overall livability of your home, allowing you to enjoy a spotless, welcoming environment without the stress and hassle of DIY cleaning.",
      },
    ];

    const incentives = [
      {
        name: "Schedule a Cleaning",
        imageSrc: calendar,
        description: "Schedule a home cleaning with Tower Maids online by selecting the date and time you'd like your cleaning professional to show up",
      },
      {
        name: "Exceptional Cleaning",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/2946/2946620.png",
        description: "Vetted and insured cleaners that have decades of years of experience. We bring our own supplies and professionalism to every cleaning",
      },
      {
        name: "Client Satisfaction",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/2058/2058768.png",
        description: "A professional cleaner shows up on time to clean your home, and you are charged only after your cleaning. If you have any questions, we're just a call away. ",
      },
      {
        name: "Sit back and Enjoy your clean home",
        imageSrc: "https://cdn-icons-png.flaticon.com/512/921/921296.png",
        description: "Let our cleaners in the home and sit back and relax. Our cleaners will be efficient and have your home cleaning. Enjoy your sprakling home in no time. ",
      },
    ];

    return (
      <div>
        <Hero
          title={title}
          subtitle={"Elevate Your Business With Eco-Friendly Cleaning Services."}
          button={"Book Now"}
          link={"/services/residential/atlanta/booking"}
          picture={
            "https://images.unsplash.com/photo-1482449609509-eae2a7ea42b7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          }
        />
        <PicRight
          subtitle={"Eco-Friendly Cleaning for Homes and Businesses"}
          title={"We’re here to help"}
          description={
            "Experience the difference with Tower Cleaning Services, specializing in eco-friendly, health-conscious cleaning solutions for commercial and residential settings. Based in Atlanta, GA & Louisville, KY, we deliver all-encompassing green janitorial services, creating a healthier, welcoming ambiance for businesses, clients, and residents."
          }
          button={"Contact Us"}
          link={"/ContactUs"}
          picture={
            "https://images.unsplash.com/photo-1581578949510-fa7315c4c350?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
          }
          color={"#f5f5f5"}
        />
        <Includes />
            <>
      <div className="relative isolate overflow-hidden py-10 ">
        <svg
          viewBox="0 0 1097 845"
          aria-hidden="true"
          className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
        >
          <path
            fill="url(#7c63f5ae-130c-4c0f-963f-50ac7fe8d2e1)"
            fillOpacity=".2"
            d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
          />
        </svg>
        <div className="mx-auto px-6 lg:px-32">
          <motion.h2
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="mb-5 text-3xl font-base tracking-wide text-[#900c3e] sm:text-6xl"
          >
            Choosing Cleaning Services
          </motion.h2>
          <div className="mx-auto max-w-2xl lg:mx-0"></div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 bg-[#f5f5f5] gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:gap-1">
            {cards.map((card) => (
              <div key={card.name} className="justify-center flex gap-x-4] p-6">
                <div className="text-base leading-7 ">
                  <motion.h3
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.6 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="font-bold text-black hover:text-[#82123f]"
                  >
                    {card.name}
                  </motion.h3>
                  <motion.p
                    id="faqText"
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.7 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="mt-2  text-[#474d53] whitespace-pre-line"
                  >
                    {card.description}
                  </motion.p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <motion.div
        initial={{ scale: 0 }}
        transition={{ ease: "easeOut", duration: 0.8 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        className="text-center mb-10"
      >
      </motion.div>
    </>
    <div className="flex items-center">
    <motion.div
              initial={{ scale: 0 }}
              transition={{ ease: "easeOut", duration: 0.4 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              className="mx-auto mt-12 grid max-w-sm grid-cols-1 gap-y-10 gap-x-8 sm:max-w-none lg:grid-cols-4"
            >
              {incentives.map((incentive, index) => (
                <div
                  key={index}
                  className="text-center sm:flex sm:text-left lg:block lg:text-center"
                >
                  <div className="sm:flex-shrink-0">
                    <div className="flow-root">
                      <img
                        className="mx-auto h-24 w-24"
                        src={incentive.imageSrc}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                    <h3 className="text-2xl font-base tracking-wide text-[#474d53] hover:text-[#900c3e]">
                      {incentive.name}
                    </h3>
                    <p className="mt-2 text-md font-base tracking-wide text-[#474d53]">
                      {incentive.description}
                    </p>
                  </div>
                </div>
              ))}
            </motion.div>
          </div>
        <RequestQuote />
      </div>
    );
  };
  
  export default Residential;
  
import { motion } from "framer-motion";
const TextImageBox = () => {
  const products = [
    {
      id: 1,
      name: "Partnership in Resilience",
      description:
        "Embracing resilience, Tower Cleaning Services forms lasting partnerships with clients, adapting to ever-changing needs while maintaining top-tier cleanliness. Together, we cultivate spaces that foster confidence, well-being, and success.",
      imageSrc:
        "https://images.unsplash.com/photo-1521790797524-b2497295b8a0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80",
      imageAlt: "",
    },
    {
      id: 2,
      name: "Trained and Certified",
      description:
        "Tower Cleaning Services takes pride in our team of vetted, insured, and experienced cleaners. Rigorously trained and certified, our professionals are committed to providing exceptional service, consistently surpassing expectations while upholding the utmost standards of cleanliness and attention to detail.",
      imageSrc:
        "https://images.unsplash.com/photo-1537511446984-935f663eb1f4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      imageAlt: "",
    },
    // More products...
  ];

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl pt-16 px-4 sm:pt-24 lg:max-w-7xl">
        <h2 className="sr-only">Products</h2>

        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-2 lg:gap-x-8">
          {products.map((product) => (
            <motion.div
              initial={{ scale: 0 }}
              transition={{ ease: "easeOut", duration: 0.4 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              key={product.id}
              className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white "
            >
              <div className="aspect-w-3 aspect-h-4 bg-gray-200 group-hover:opacity-75 sm:aspect-none sm:h-96">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                />
              </div>
              <div className="flex flex-1 flex-col space-y-2 p-4">
                <h3 className="text-2xl font-base tracking-wide text-[#900c3e] text-center">
                  <a href={product.href}>
                    <span aria-hidden="true" className="absolute inset-0" />
                    {product.name}
                  </a>
                </h3>
                <p className="text-xl font-base tracking-wide text-[#474d53]">
                  {product.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TextImageBox;

import React, {useRef, useState, useLayoutEffect} from "react";
import { iframeResizer } from 'iframe-resizer';

const AtlBooking = () => {
  const [iframeHeight, setIframeHeight] = useState('800px');
  const iframeRef = useRef(null);

  useLayoutEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleMessage = (event) => {
    if (event.data.message === 'iframe-height') {
      setIframeHeight(event.data.height);
    }
  };

  useLayoutEffect(() => {
    iframeResizer({ log: false }, iframeRef.current);
  }, []);

  return (
    <div>
    <div className="pb-20"></div>
    <iframe
          src="https://parkmaids.bookingkoala.com/booknow?embed=tru"
          style={{ border: 'none', minHeight: iframeHeight, height: iframeHeight, width: "100%" }}
          scrolling="no"
          ref={iframeRef}
          allow-same-origin
          loading="lazy"
        ></iframe>
      <script dangerouslySetInnerHTML={{
      __html: `
        ;(function () {
          function initIframeResizer() {
            window.iFrameResizer = {
              heightCalculationMethod: 'bodyOffset',
              log: false,
              checkOrigin: false,
            };
            window.iFrameResizer.resize();
          }

          if (document.readyState === 'complete') {
            initIframeResizer();
          } else {
            window.addEventListener('load', initIframeResizer);
          }
        })();
      `,
    }}></script>
</div>
  );
};

export default AtlBooking;
import { motion } from "framer-motion";

const BlogPostForm = () => {
  const jobOpenings = [
    {
      id: 1,
      article: "Factors to Consider Before Hiring a Cleaning Service",
      href: "https://posts.gle/pDWLPu",
      description:
        "This article provides tips for homeowners who are unsure if a cleaning service can effectively clean their homes. It covers factors such as the type of cleaning services offered and the level of clutter or disrepair in the home.",
      blogOrigin: "Google Blogs",
      date: "Mar 23, 2023",
    },
    {
      id: 2,
      article:
        "How to Clean Your Windows without Leaving Streaks: Tips and Tricks",
      href: "https://posts.gle/6uxHQh",
      description:
        "This article provides a step-by-step guide for cleaning windows without leaving streaks. It covers tips such as starting with a clean window, making a cleaning solution, applying the solution in a circular motion, etc.",
      blogOrigin: "Google Blogs",
      date: "Mar 16, 2023",
    },
    {
      id: 3,
      article: "Effects of Oven Cleaner on Kitchen Countertops",
      href: "https://posts.gle/EnkV2e",
      description:
        "This article explains the potential effects of using oven cleaner on kitchen countertops, depending on the material of the countertops and the specific type of oven cleaner being used. It highlights the risk of damage, discoloration, and residue left behind.",
      blogOrigin: "Google Blogs",
      date: "Mar 15, 2023",
    },
  ];

  return (
    <div className="bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <motion.div
          initial={{ scale: 0 }}
          transition={{ ease: "easeOut", duration: 0.4 }}
          whileInView={{ scale: 1 }}
          viewport={{ once: true }}
          className="mx-auto flex max-w-2xl flex-col items-end justify-between gap-16 lg:mx-0 lg:max-w-none lg:flex-row"
        >
          <div className="w-full lg:max-w-xl lg:flex-auto ">
            <h2 className="text-3xl font-base tracking-wide mb-12 text-[#900c3e] sm:text-6xl">
              Latest Blog Posts
            </h2>
            <ul className="-my-8 divide-y divide-gray-100">
              {jobOpenings.map((opening) => (
                <li key={opening.id} className="py-8">
                  <dl className="relative flex flex-wrap gap-x-3">
                    <dd className="w-full flex-none text-lg font-semibold tracking-tight text-gray-900 hover:text-[#941546da]">
                      <a href={opening.href}>
                        {opening.article}
                        <span className="absolute inset-0" aria-hidden="true" />
                      </a>
                    </dd>
                    <dd className="mt-2 w-full flex-none text-base leading-7 text-gray-600">
                      {opening.description}
                    </dd>
                    <dd className="mt-4 text-base font-semibold leading-7 text-gray-900">
                      {opening.blogOrigin}
                    </dd>
                    <dd className="mt-4 ml-4 flex items-center gap-x-3 text-base leading-7 text-gray-500">
                      {opening.date}
                    </dd>
                  </dl>
                </li>
              ))}
            </ul>
          </div>

        </motion.div>
        <div className="mt-8 flex border-t border-gray-100 pt-8">
          <a
            href="/ContactUs"
            className="text-sm font-semibold leading-6 text-[#900c3e] hover:text-[#82123f]"
          >
            Request Contact Form <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
      
    </div>
  );
};

export default BlogPostForm;

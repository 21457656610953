import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "../components/Carousel";
import { motion } from "framer-motion";

import React from "react";
import Slider from "react-slick";
import BlogPostForm from "./BlogPostForm";

export default function SimpleSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const testimonials = [
    {
      name: "Adria Carter",
      title: "Residential Client",
      review:
        "I was extremely impressed with not only how beautiful my home looks, but their charming personalities. They were specific with questions, ensured that I was pleased, and I bragged to my friends. I will certainly be a return customer.",
      picture:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    },
    {
      name: "Jess Robinson",
      title: "Residential Client",
      review:
        "Tower Cleaning Services exceeded my expectations! Even my hard-to-please mother was impressed with their work. Their communication was top-notch and we'll definitely be using them for a monthly deep clean going forward. Highly recommend!",
      picture:
        "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    },
    {
      name: "Amir Bagheri",
      title: "Property Manager",
      review:
        "I just moved from Prospect, and I needed an urgent house cleaning to keep my security deposit. I reached out and someone from Tower Maids gave me a call and set everything up after a few questions. The place was spotless when I returned for the final walk through! 5 stars!",
      picture:
        "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    },
    {
      name: "Steven Covington",
      title: "Residential Client",
      review:
        "The cleaner who did the deep cleaning was very professional and personable. The service was also speedy, and I am completely satisfied with the whole experience.",
      picture:
        "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    },
  ];

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div>
        <div
          className={className}
          style={{
            ...style,
            height: "40px",
            width: "40px",
            display: "flex",
            background: "rgba(144,12,62,255)",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "100%",
            marginLeft: "4px",
            zIndex: 40,
          }}
          onClick={onClick}
        />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          height: "40px",
          width: "40px",
          display: "flex",
          background: "rgba(144,12,62,255)",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "100%",
          marginRight: "4px",
          zIndex: 40,
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <div className="flex flex-col justify-center items-center md:flex-row ">
    <div className="mx-8 max-w-xs sm:max-w-md md:max-w-2xl lg:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl">      <BlogPostForm /></div>
      <div className="mx-8 max-w-xs sm:max-w-md md:max-w-2xl lg:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <motion.div
              initial={{ scale: 0 }}
              transition={{ ease: "easeOut", duration: 0.4 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              key={index}
            >
              <h2 className="text-3xl pt-7 font-base tracking-wide text-[#900c3e] mt-9 sm:text-6xl w-full text-center">
                Testimonials
              </h2>
              <Carousel
                name={testimonial.name}
                title={testimonial.title}
                review={testimonial.review}
              />
            </motion.div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

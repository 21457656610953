import { Link } from "react-router-dom";
import { motion } from "framer-motion";
const RequestQuote = () => {
  return (
    <div className="relative isolate mt-10 overflow-hidden bg-[#474d53] py-24 px-6 sm:py-32 lg:px-8">
      <img
        src="https://images.unsplash.com/photo-1603712725038-e9334ae8f39f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover brightness-50"
      />
      <svg
        viewBox="0 0 1097 845"
        aria-hidden="true"
        className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
      >
        <path
          fill="url(#10724532-9d81-43d2-bb94-866e98dd6e42)"
          fillOpacity=".2"
          d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
        />
        <defs>
          <linearGradient
            id="10724532-9d81-43d2-bb94-866e98dd6e42"
            x1="1097.04"
            x2="-141.165"
            y1=".22"
            y2="363.075"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#776FFF" />
            <stop offset={1} stopColor="#FF4694" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        viewBox="0 0 1097 845"
        aria-hidden="true"
        className="absolute left-1/2 -top-52 -z-10 w-[68.5625rem] -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
      >
        <path
          fill="url(#8ddc7edb-8983-4cd7-bccb-79ad21097d70)"
          fillOpacity=".2"
          d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
        />
        <defs>
          <linearGradient
            id="8ddc7edb-8983-4cd7-bccb-79ad21097d70"
            x1="1097.04"
            x2="-141.165"
            y1=".22"
            y2="363.075"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#776FFF" />
            <stop offset={1} stopColor="#FF4694" />
          </linearGradient>
        </defs>
      </svg>
      <div className="mx-auto max-w-3xl text-center">
        <motion.h6
          initial={{ scale: 0 }}
          transition={{ ease: "easeOut", duration: 0.5 }}
          whileInView={{ scale: 1 }}
          viewport={{ once: true }}
          className="text-4xl font-light tracking-wide text-white sm:text-6xl"
        >
          Cost-Effective Cleaning Solutions
        </motion.h6>
        <motion.h6
          initial={{ scale: 0 }}
          transition={{ ease: "easeOut", duration: 0.6 }}
          whileInView={{ scale: 1 }}
          viewport={{ once: true }}
          className="mt-6 text-xl leading-8 font-light tracking-wide text-white"
        >
          At Tower Cleaning Services, we provide customizable cleaning plans and
          competitive pricing options that prioritize both quality and
          affordability. Get in touch with us today to discover how our
          cost-effective cleaning solutions can elevate your space without
          breaking the bank.
        </motion.h6>
      </div>
      <motion.div
        initial={{ scale: 0 }}
        transition={{ ease: "easeOut", duration: 0.7 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        className="text-center mt-10"
      >
        <Link
          to="/contactUs"
          className="rounded-3xl bg-[#900c3e] px-4 py-3.5 text-md font-light text-white shadow-xl hover:bg-[#82123f] transition delay-50 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 sm:text-lg"
        >
          Request a Quote
        </Link>
      </motion.div>
    </div>
  );
};

export default RequestQuote;

import { motion } from "framer-motion";
const Mission = () => {
  const incentives = [
    {
      name: "Exceptional Cleaning",
      imageSrc: "https://cdn-icons-png.flaticon.com/512/2946/2946620.png",
      description: "Delivering exceptional cleaning for pristine spaces",
    },
    {
      name: "Client Satisfaction",
      imageSrc: "https://cdn-icons-png.flaticon.com/512/2058/2058768.png",
      description: "Prioritizing client satisfaction in every interaction",
    },
    {
      name: "Quality Service",
      imageSrc: "https://cdn-icons-png.flaticon.com/512/921/921296.png",
      description: "Providing top-quality service with unmatched expertise",
    },
    {
      name: "Customer Guarantee",
      imageSrc: "https://cdn-icons-png.flaticon.com/512/2946/2946636.png",
      description: "Offering a steadfast customer guarantee for peace of mind",
    },
  ];

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-18">
        <div className="rounded-2xl px-6 py-16 sm:p-16">
          <div className="mx-auto max-w-xl lg:max-w-none">
            <div className="text-center">
              <motion.h2
                initial={{ scale: 0 }}
                transition={{ ease: "easeOut", duration: 0.4 }}
                whileInView={{ scale: 1 }}
                viewport={{ once: true }}
                className="text-3xl font-base tracking-wide mb-14 text-[#900c3e] sm:text-6xl"
              >
                Our Mission and Values
              </motion.h2>
              <motion.p
                initial={{ scale: 0 }}
                transition={{ ease: "easeOut", duration: 0.4 }}
                whileInView={{ scale: 1 }}
                viewport={{ once: true }}
                className=" text-xl font-base tracking-wide mb-24 text-[#474d53] sm:text-2xl"
              >
                Empowering clean, healthy spaces through exceptional service,
                environmental responsibility, and unwavering commitment to
                client satisfaction.
              </motion.p>
            </div>
            <motion.div
              initial={{ scale: 0 }}
              transition={{ ease: "easeOut", duration: 0.4 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              className="mx-auto mt-12 grid max-w-sm grid-cols-1 gap-y-10 gap-x-8 sm:max-w-none lg:grid-cols-4"
            >
              {incentives.map((incentive, index) => (
                <div
                  key={index}
                  className="text-center sm:flex sm:text-left lg:block lg:text-center"
                >
                  <div className="sm:flex-shrink-0">
                    <div className="flow-root">
                      <img
                        className="mx-auto h-24 w-24"
                        src={incentive.imageSrc}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                    <h3 className="text-2xl font-base tracking-wide text-[#474d53] hover:text-[#900c3e]">
                      {incentive.name}
                    </h3>
                    <p className="mt-2 text-md font-base tracking-wide text-[#474d53]">
                      {incentive.description}
                    </p>
                  </div>
                </div>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;

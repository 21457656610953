import LocationTypes from "../components/LocationTypes";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Atlanta from "../images/Atlanta.jpg"
import Lexington from "../images/Lexington.jpg"
import Cincinatti from "../images/Cincinatti.jpg"
import Louisville from "../images/Louisville.jpg"
import Nashville from "../images/Nashville.jpg"
import BowlingGreen from "../images/BowlingGreen.jpg"


const LocationDetails = () => {
  const location = useLocation();
  const city = new URLSearchParams(location.search).get("title");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const locations = {
    Louisville: {
      city: "Louisville",
      heroTitle: "Louisville Cleaning",
      heroSubtitle: "Our Home Base for Exceptional Cleaning Services",
      title1: "Cleaning Services in Louisville, KY",
      subtitle1:
        "Providing Expert Cleaning Solutions for Homes and Businesses in the Derby City",
      description1: `Louisville, Kentucky, is a vibrant city with a rich history and a growing economy. Home to many businesses, universities, and cultural attractions, Louisville is a hub of activity and opportunity. At our cleaning company, we are proud to serve the Louisville community, providing expert cleaning solutions for a wide range of residential and commercial properties.\n\nWhether you need regular cleaning services for your home or specialized cleaning for your business, we are committed to delivering the highest quality cleaning services to help you maintain a clean and healthy living or working environment. Contact us today to learn more about our expert cleaning solutions in Louisville, Kentucky.`,
      title2: "Louisville Deep Cleaning Services",
      subtitle2: "Keeping Your Home Spotless and Sanitized",
      description2: `Louisville, Kentucky is known for its vibrant nightlife, delicious food, and diverse culture. At our cleaning company, we understand that residents of Louisville want a home that reflects their unique style and personality.\n\nThat's why we offer a variety of cleaning services, including deep cleans, recurring cleans, move in/out cleans, office cleans, Airbnb cleans, construction cleans, and commercial cleans.`,
      title3: "Louisville Commercial Cleaning Services",
      subtitle3:
        "Ensure Every Nook and Cranny Is Thoroughly Cleaned and Sanitized",
      description3: `Our experienced team of cleaning professionals uses the latest tools and techniques to deliver a spotless, sanitized environment that exceeds your expectations.\n\nWhether you need a one-time deep clean or ongoing weekly cleaning services, we work with you to create a customized plan that fits your needs and budget. Contact us today to learn more about our expert cleaning solutions for Louisville residents.`,
      picture: {Louisville}
    },
    Lexington: {
      city: "Lexington",
      heroTitle: "Lexington Cleaning",
      heroSubtitle: "Our Home Base for Exceptional Cleaning Services",
      title1: "Cleaning Services in Lexington, Kentucky",
      subtitle1:
        "Providing Commercial and Residential Cleaning Solutions in Lexington, Kentucky",
      description1: `Located in the heart of Kentucky, Lexington is a vibrant city known for its rich history, horse farms, and thriving economy. With a population of over 300,000, it's the second-largest city in the state and home to numerous businesses, restaurants, and cultural attractions.\n\nOur cleaning company is proud to serve the Lexington community, offering expert cleaning solutions for a wide range of residential and commercial spaces. From historic homes and modern apartments to office buildings and retail stores, we are committed to providing top-notch cleaning services that meet the unique needs of our clients.`,
      title2: "Lexington Deep Cleaning Services",
      subtitle2: "Keeping Your Home Spotless and Sanitized",
      description2: `Lexington, Kentucky is known for its horse farms, historic landmarks, and Southern charm. At our cleaning company, we understand that residents of Lexington want a clean and welcoming home that reflects their unique style and hospitality.\n\nThat's why we offer a range of cleaning services, including deep cleans, recurring cleans, move in/out cleans, office cleans, Airbnb cleans, construction cleans, and commercial cleans.`,
      title3: "Lexington Commercial Cleaning Service",
      subtitle3:
        "Ensure Every Nook and Cranny Is Thoroughly Cleaned and Sanitizeds",
      description3: `Our experienced team of cleaning professionals is equipped with the latest tools and techniques to deliver a spotless, sanitized environment that exceeds your expectations.\n\nWhether you need a one-time deep clean or ongoing weekly cleaning services, we work with you to create a customized plan that fits your needs and budget. Contact us today to learn more about our expert cleaning solutions for Lexington residents.`,
      picture: {Lexington}
    },
    "Bowling Green": {
      city: "BowlingGreen",
      heroTitle: "Bowling Green Cleaning",
      heroSubtitle: "Professional Cleaning for Homes, Offices, and Beyond",
      title1: "Cleaning Services in Bowling Green, Kentucky ",
      subtitle1:
        "Providing Commercial and Residential Cleaning Solutions in Bowling Green, Kentucky",
      description1: `Tower Cleaning Services in Bowling Green provides expert commercial cleaning solutions to businesses of all sizes. Our experienced team of professionals is equipped with the latest tools and techniques to deliver customized cleaning plans that meet your specific needs and exceed your expectations.\n\nFrom office buildings and medical facilities to retail stores and restaurants, we have the expertise to handle all your commercial cleaning needs. We also offer residential cleaning services to keep your home clean and healthy.`,
      title2: "Bowling Green Deep Cleaning Services ",
      subtitle2: "Keeping Your Home Spotless and Sanitized",
      description2: `Bowling Green, Kentucky is a thriving city known for its historic landmarks, beautiful parks, and vibrant community.\n\nAt our cleaning company, we offer a range of expert cleaning services for residential and commercial properties in Bowling Green and the surrounding areas.`,
      title3: "Bowling Green Commercial Cleaning Service ",
      subtitle3:
        "Ensure Every Nook and Cranny Is Thoroughly Cleaned and Sanitizeds ",
      description3: `Our services include deep cleans, recurring cleans, move in/out cleans, office cleans, Airbnb cleans, construction cleans, and commercial cleans.\n\nWhether you need a one-time clean or regular cleaning services, our experienced team of professionals is here to help you maintain a clean and healthy living or work environment. Contact us today to learn more about our expert cleaning solutions for Bowling Green.`,
      picture: {BowlingGreen}
    },
    Cincinnati: {
      city: "Cincinatti",
      heroTitle: "Cincinnati Cleaning",
      heroSubtitle:
        "Comprehensive Cleaning Services for a Sparkling Clean Space",
      title1: "Cleaning Services in Cincinnati, Ohio ",
      subtitle1:
        "Providing Commercial and Residential Cleaning Solutions in Cincinnati, Ohio",
      description1: `Tower Cleaning Services in Cincinnati provides expert commercial cleaning solutions to businesses of all sizes. Our experienced team of professionals is equipped with the latest tools and techniques to deliver customized cleaning plans that meet your specific needs and exceed your expectations.\n\nFrom small offices to large commercial buildings, we have the expertise to handle all your commercial cleaning needs. We also offer residential cleaning services to keep your home looking its best.`,
      title2: "Cincinnati Deep Cleaning Services ",
      subtitle2: "Keeping Your Home Spotless and Sanitized",
      description2: `Cincinnati, Ohio is a bustling city full of rich history and culture. At our cleaning company, we provide a variety of expert cleaning services for residential and commercial properties in Cincinnati and the surrounding areas.\n\nOur services include deep cleans, recurring cleans, move in/out cleans, office cleans, Airbnb cleans, construction cleans, and commercial cleans.`,
      title3: "Cincinnati Commercial Cleaning Service ",
      subtitle3:
        "Ensure Every Nook and Cranny Is Thoroughly Cleaned and Sanitizeds ",
      description3: `Our experienced team of professionals is equipped with the latest tools and techniques to deliver a clean and healthy environment for you and your family or employees.\n\nWhether you need a one-time clean or regular cleaning services, we work with you to create a customized plan that fits your needs and budget. Contact us today to learn more about our expert cleaning solutions for Cincinnati.`,
      picture: {Cincinatti}
    },
    Nashville: {
      city: "Nashville",
      heroTitle: "Nashville Cleaning",
      heroSubtitle:
        "Quality Cleaning Services for a Refreshing and Inviting Space",
      title1: "Cleaning Services in Nashville, Tennessee ",
      subtitle1:
        "Providing Commercial and Residential Cleaning Solutions in Nashville, Tennessee",
      description1: `Tower Cleaning Services in Nashville provides expert commercial cleaning solutions to businesses of all sizes. Our experienced team of professionals is equipped with the latest tools and techniques to deliver customized cleaning plans that meet your specific needs and exceed your expectations.\n\nFrom office buildings and medical facilities to retail stores and restaurants, we have the expertise to handle all your commercial cleaning needs. We also offer residential cleaning services to keep your home clean and healthy.`,
      title2: "Nashville Deep Cleaning Services ",
      subtitle2: "Keeping Your Home Spotless and Sanitized",
      description2: `Nashville, Tennessee is known for its lively music scene, rich history, and southern charm. At our cleaning company, we offer a variety of expert cleaning services for residential and commercial properties in Nashville and the surrounding areas.\n\nOur services include deep cleans, recurring cleans, move in/out cleans, office cleans, Airbnb cleans, construction cleans, and commercial cleans.`,
      title3: "Nashville Commercial Cleaning Service ",
      subtitle3:
        "Ensure Every Nook and Cranny Is Thoroughly Cleaned and Sanitizeds ",
      description3: `Our experienced team of professionals is equipped with the latest tools and techniques to deliver a clean and healthy environment for you and your family or employees.\n\nWhether you need a one-time clean or regular cleaning services, we work with you to create a customized plan that meets your needs and budget. Contact us today to learn more about our expert cleaning solutions for Nashville.`,
      picture: {Nashville}
    },
    Atlanta: {
      city: "Atlanta",
      heroTitle: "Atlanta Cleaning",
      heroSubtitle: "Top-Notch Cleaning Solutions for Your Home or Office",
      title1: "Cleaning Services in Atlanta, GA",
      subtitle1:
        "Providing Commercial and Residential Cleaning Solutions in Atlanta, GA",
      description1: `Tower Cleaning Services in Atlanta provides expert commercial cleaning solutions to businesses of all sizes. Our experienced team of professionals is equipped with the latest tools and techniques to deliver customized cleaning plans that meet your specific needs and exceed your expectations.\n\nFrom small offices to large commercial buildings, we have the expertise to handle all your commercial cleaning needs. We also offer residential cleaning services to keep your home looking its best.`,
      title2: "Atlanta Deep Cleaning Services ",
      subtitle2: "Keeping Your Home Spotless and Sanitized",
      description2: `Atlanta, GA is a vibrant city known for its cultural events, sports teams, and beautiful parks. At our cleaning company, we provide a range of expert cleaning services for residential and commercial properties in Atlanta and the surrounding areas.\n\nOur services include deep cleans, recurring cleans, move in/out cleans, office cleans, Airbnb cleans, construction cleans, and commercial cleans.`,
      title3: "Atlanta Commercial Cleaning Service ",
      subtitle3:
        "Ensure Every Nook and Cranny Is Thoroughly Cleaned and Sanitizeds ",
      description3: `Our experienced team of professionals is equipped with the latest tools and techniques to deliver a clean and healthy environment for you and your family or employees.\n\nWhether you need a one-time clean or regular cleaning services, we work with you to create a customized plan that fits your needs and budget. Contact us today to learn more about our expert cleaning solutions for Atlanta.`,
      picture: {Atlanta}
    },
  };

  return (
    <>
      <LocationTypes data={locations[city]} />
    </>
  );
};

export default LocationDetails;

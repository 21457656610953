import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const IndustryTypes = () => {
  const industries = [
    {
      name: "Offices & Buildings",
      imageSrc: "https://cdn-icons-png.flaticon.com/128/2086/2086058.png",
      description:
        "Expert cleaning solutions for all types of corporate spaces",
      link: "/industries/offices-buildings?title=Offices and Buildings&subtitle=Expert Cleaning Solutions for All Types of Corporate Spaces&image=url",
    },
    {
      name: "Medical Facilities",
      imageSrc: "https://cdn-icons-png.flaticon.com/128/2895/2895071.png",
      description: "Cleaning services for a healthy, sanitized environment",
      link: "/industries/medical-facilities?title=Medical Facilities&subtitle=Cleaning Services for a Healthy, Sanitized Environment&image=url",
    },
    {
      name: "Industrial / Manufacturing",
      imageSrc: "https://cdn-icons-png.flaticon.com/128/1098/1098423.png",
      description: "Advanced cleaning solutions for industrial settings",
      link: "/industries/industrial-manufacturing?title=Industrial / Manufacturing&subtitle=Advanced Cleaning Solutions for Industrial Settings&image=url",
    },
    {
      name: "Education / Schools",
      imageSrc: "https://cdn-icons-png.flaticon.com/128/158/158234.png",
      description: "Comprehensive cleaning for educational facilities",
      link: "/industries/education-schools?title=Education / Schools&subtitle=Comprehensive Cleaning for Educational Facilities&image=url",
    },
    {
      name: "Banks / Financial",
      imageSrc: "https://cdn-icons-png.flaticon.com/128/3635/3635987.png",
      description: "Impeccable cleaning for banks and financial institutions",
      link: "/industries/banks-financial?title=Banks / Financial&subtitle=Impeccable Cleaning for Banks and Financial Institutions&image=url",
    },
    {
      name: "Government Facilities",
      imageSrc: "https://cdn-icons-png.flaticon.com/128/3061/3061878.png",
      description: "Trusted cleaning services for government buildings",
      link: "/industries/government?title=Government Cleaning&subtitle=Trusted Cleaning Services for Government Buildings&image=url",
    },
    {
      name: "Religious Facilities",
      imageSrc: "https://cdn-icons-png.flaticon.com/128/10096/10096024.png",
      description: "Customized cleaning solutions for religious institutions",
      link: "/industries/religious?title=Religious Facilities Cleaning&subtitle=Customized Cleaning Solutions for Religious Institutions&image=url",
    },
    {
      name: "Health Clubs / Gyms",
      imageSrc: "https://cdn-icons-png.flaticon.com/128/1414/1414446.png",
      description: "Specialized cleaning for fitness centers and health clubs",
      link: "/industries/health-clubs?title=Health Clubs / Gyms&subtitle=Specialized Cleaning for Fitness Centers, Health Clubs, and Gyms&image=url",
    },
    {
      name: "Co-Working / Hybrid",
      imageSrc: "https://cdn-icons-png.flaticon.com/512/5817/5817946.png",
      description: "Flexible cleaning services for co-working spaces",
      link: "/industries/events?title=Co-Working / Hybrid Offices&subtitle=Flexible Cleaning Services for Co-Working Spaces&image=url",
    },
    {
      name: "Retail Spaces / Stores",
      imageSrc: "https://cdn-icons-png.flaticon.com/128/2769/2769277.png",
      description:
        "Exceptional cleaning for a sparkling, inviting retail environment",
      link: "/industries/retail?title=Retail Spaces / Stores&subtitle=Exceptional Cleaning for a Sparkling, Inviting Retail Environment&image=url",
    },
    {
      name: "Restaurant / Hospitality",
      imageSrc: "https://cdn-icons-png.flaticon.com/128/3208/3208003.png",
      description:
        "Meticulous cleaning for restaurants and hospitality settings",
      link: "/industries/restaurant-hospitality?title=Restaurant / Hospitality&subtitle=Meticulous Cleaning for Restaurants and Hospitality Settings&image=url",
    },
    {
      name: "Gas Station Facilities",
      imageSrc: "https://cdn-icons-png.flaticon.com/128/5900/5900275.png",
      description:
        "Quality cleaning for a pristine and safe gas station environment",
      link: "/industries/gas-station?title=Gas Station Cleaning&subtitle=Quality Cleaning for a Pristine and Safe Gas Station Environment&image=url",
    },
  ];

  return (
    <div className="bg-gray-50">
      <h2 className="sr-only">Our perks</h2>
      <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-12 gap-x-28 px-4 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
          {industries.map((industry) => (
            <Link to={industry.link} key={industry.name} className="sm:flex">
              <div className="sm:flex-shrink-0">
                <div className="flow-root">
                  <motion.img
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.5 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="h-12 w-12"
                    src={industry.imageSrc}
                    alt=""
                  />
                </div>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <motion.h3
                  initial={{ scale: 0 }}
                  transition={{ ease: "easeOut", duration: 0.6 }}
                  whileInView={{ scale: 1 }}
                  viewport={{ once: true }}
                  className="text-2xl font-semi-bold tracking-tight text-[#474d53] hover:text-[#900c3e]"
                >
                  {industry.name}
                </motion.h3>
                <motion.p
                  initial={{ scale: 0 }}
                  transition={{ ease: "easeOut", duration: 0.7 }}
                  whileInView={{ scale: 1 }}
                  viewport={{ once: true }}
                  className="mt-2 text-md text-[#474d53]"
                >
                  {industry.description}
                </motion.p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndustryTypes;

import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";

const Faq = () => {
  const faqs = [
    {
      question:
        "How often should I schedule a cleaning service for my home or office?",
      answer:
        "The frequency of your cleaning service will depend on your unique needs and circumstances. For example, high-traffic areas in a commercial space may require daily cleaning, while a residential space may only require weekly or bi-weekly cleaning. We offer flexible scheduling options to accommodate your needs and can work with you to develop a customized cleaning plan.",
    },
    {
      question: "Are your cleaning products environmentally friendly?",
      answer:
        "Yes, we take pride in offering eco-friendly cleaning solutions for both commercial and residential spaces. Our cleaning products are specially formulated to be safe for the environment and for the health of your customers, employees, or family members. We also use equipment and techniques that minimize waste and conserve resources.",
    },
    {
      question: "How long does a typical cleaning service take?",
      answer:
        "The length of time required for a cleaning service will depend on the size of the space, the level of cleaning needed, and the specific tasks requested. However, we strive to work efficiently and effectively to minimize disruption to your daily routine. Our team of professional cleaners is highly trained and experienced, ensuring that your space is cleaned thoroughly and in a timely manner.",
    },
    {
      question: "Are your cleaners background-checked and insured?",
      answer:
        "Yes, all of our cleaners are thoroughly vetted, background-checked, and insured. We understand the importance of providing our clients with peace of mind and trust, and we take every precaution to ensure that our cleaning team is reliable and professional. You can rest assured that your space is in good hands with our team of experienced and trustworthy cleaners.",
    },
    // More questions...
  ];

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-20 lg:px-8">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <motion.h2
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="text-3xl font-base leading-10 tracking-wide text-[#900c3e] sm:text-6xl"
          >
            FAQ
          </motion.h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq, index) => (
              <Disclosure as="div" key={index} className="pt-6">
                {({ open }) => (
                  <>
                    <motion.dt
                      initial={{ scale: 0 }}
                      transition={{ ease: "easeOut", duration: 0.6 }}
                      whileInView={{ scale: 1 }}
                      viewport={{ once: true }}
                    >
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-[#474d53] hover:text-[#82123f]">
                        <span className="text-ls font-bold leading-7 sm:text-xl">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </motion.dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <motion.p
                        initial={{ scale: 0 }}
                        transition={{ ease: "easeOut", duration: 0.7 }}
                        whileInView={{ scale: 1 }}
                        viewport={{ once: true }}
                        className="text-sm leading-7 text-gray-600 sm:text-base"
                      >
                        {faq.answer}
                      </motion.p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Faq;

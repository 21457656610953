import Hero from "../components/Hero";
import Includes from "../components/Includes";
import MoreInfoService from "../components/MoreInfoService";
import PicLeft from "../components/PicLeft";
import RequestQuote from "../components/RequestQuote";
import OurServices from "../components/OurServices";
import PicRight from "../components/PicRight";
import { useEffect } from "react";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero
        title={"Services"}
        subtitle={
          "Impeccable Commercial Solutions & Meticulous Residential Care"
        }
        button={"Call Us At (912)-455-3650"}
        link={"/"}
        picture={
          "https://images.unsplash.com/photo-1482449609509-eae2a7ea42b7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        }
      />
      <OurServices />
      <PicRight
        subtitle={"Eco-Friendly Cleaning for Homes and Businesses"}
        title={"We’re here to help"}
        description={
          "Experience the difference with Tower Cleaning Services, specializing in eco-friendly, health-conscious cleaning solutions for commercial and residential settings. Based in Atlanta, GA & Louisville, KY, we deliver all-encompassing green janitorial services, creating a healthier, welcoming ambiance for businesses, clients, and residents."
        }
        link={"/contactUs"}
        button={"Contact Us"}
        picture={
          "https://images.unsplash.com/photo-1581578949510-fa7315c4c350?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
        }
        color={"#f5f5f5"}
      />
      <Includes />
      <PicLeft
        subtitle={
          "Enjoy a Healthier and Cleaner Environment with Our Eco-Friendly Cleaning Solutions"
        }
        title={
          "Green Janitorial Services for Commercial and Residential Spaces"
        }
        description={
          "Experience the difference with our eco-friendly and health-conscious cleaning solutions for commercial and residential spaces. Our innovative cleaning company offers businesses comprehensive green janitorial services, creating a healthier environment for your customers and employees. Our cleaning products are environmentally friendly, non-toxic, and biodegradable, ensuring the safety of your space and the planet. Trust us to deliver all-encompassing cleaning services that foster a healthier, welcoming ambiance for businesses, clients, and residents alike."
        }
        link={"/contactUs"}
        button={"Contact Us"}
        picture={
          "https://plus.unsplash.com/premium_photo-1678304224606-b68796c4e0ef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        }
        color={"#f5f5f5"}
      />
      <PicRight
        subtitle={"Choose the Right Cleaning Plan for Your Unique Needs"}
        title={"Customizable Cleaning Solutions for Homes and Offices"}
        description={
          "At our cleaning company, we understand that every space has unique cleaning needs. That's why we offer customizable cleaning services for both commercial and residential spaces. From deep cleaning to regular maintenance cleaning, we tailor our services to fit your specific needs and preferences. Our expert cleaners use only the best equipment and cleaning products to deliver a spotless and comfortable environment. Choose the right cleaning plan for your unique needs and enjoy a stress-free and clean space."
        }
        link={"/contactUs"}
        button={"Contact Us"}
        picture={
          "https://images.unsplash.com/photo-1582816380203-03126488757f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
        }
        color={"#f5f5f5"}
      />
      <PicLeft
        subtitle={
          "Trust Our Vetting Process to Ensure Safe and Professional Services"
        }
        title={"Professional and Experienced Cleaners"}
        description={
          "At our cleaning company, we understand the importance of providing safe and professional cleaning services to our clients. That's why we have a rigorous vetting process to ensure that all of our cleaners are experienced, trained, and insured. Our cleaning professionals are equipped with the latest technology and equipment to deliver quality cleaning services for commercial and residential spaces. Trust our team to provide exceptional cleaning services that meet and exceed your expectations."
        }
        link={"/contactUs"}
        button={"Contact Us"}
        picture={
          "https://plus.unsplash.com/premium_photo-1678718606493-9acf0fb85e1b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80"
        }
        color={"#f5f5f5"}
      />
      <RequestQuote />
    </div>
  );
};

export default Services;

import {
  HomeIcon,
  FaceSmileIcon,
  CalendarIcon,
} from "@heroicons/react/20/solid";
import { motion } from "framer-motion";

const WhyUsBox = () => {
  const cards = [
    {
      name: "Effortless Booking",
      description:
        "Easily book commercial or residential sessions. Choose a date and time, and let our experts deliver a pristine space.",
      icon: CalendarIcon,
    },
    {
      name: "Customer Guarantee",
      description:
        "Our professional cleaner arrives on time, with payment due only after completion. For questions, our team is just a call away.",
      icon: HomeIcon,
    },
    {
      name: "Relax & Recharge",
      description:
        "Sit back and unwind; Tower Cleaning Services is en route. Delight in your sparkling home in no time at all.",
      icon: FaceSmileIcon,
    },
  ];

  return (
    <div className="relative isolate overflow-hidden bg-white py-16 sm:py-20">
      {/* <img
          src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        /> */}
      <svg
        viewBox="0 0 1097 845"
        aria-hidden="true"
        className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
      >
        <path
          fill="url(#7c63f5ae-130c-4c0f-963f-50ac7fe8d2e1)"
          fillOpacity=".2"
          d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
        />
        {/* <defs>
            <linearGradient
              id="7c63f5ae-130c-4c0f-963f-50ac7fe8d2e1"
              x1="1097.04"
              x2="-141.165"
              y1=".22"
              y2="363.075"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#776FFF" />
              <stop offset={1} stopColor="#FF4694" />
            </linearGradient>
          </defs> */}
      </svg>
      <svg
        viewBox="0 0 1097 845"
        aria-hidden="true"
        className="absolute left-1/2 -top-52 -z-10 w-[68.5625rem] -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
      >
        <path
          fill="url(#49c00522-612e-41d3-bb32-ce7d1fa28850)"
          fillOpacity=".2"
          d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
        />
        {/* <defs>
            <linearGradient
              id="49c00522-612e-41d3-bb32-ce7d1fa28850"
              x1="1097.04"
              x2="-141.165"
              y1=".22"
              y2="363.075"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#776FFF" />
              <stop offset={1} stopColor="#FF4694" />
            </linearGradient>
          </defs> */}
      </svg>
      <div className="mx-auto max-w-8xl ml-7">
        {/* <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-[#900c3e] sm:text-6xl">How Tower Maids Works</h2>
            <p className="mt-6 text-lg leading-8 text-black">
              Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
              fugiat veniam occaecat fugiat aliqua.
            </p>
          </div> */}
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-20">
          {cards.map((card) => (
            <motion.div
              initial={{ scale: 0 }}
              transition={{ ease: "easeOut", duration: 0.4 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              key={card.name}
              className="flex gap-x-4  bg-white/5 p-6 ring-1 ring-inset ring-white/10"
            >
              <card.icon
                className="h-10 w-8 flex-none text-[#474d53] "
                aria-hidden="true"
              />
              <div className="text-base leading-7">
                <h3 className="font-base tracking-wide text-3xl text-[#474d53] hover:text-[#900c3e]">
                  {card.name}
                </h3>
                <p className="mt-2 text-xl font-base tracking-wide text-[#474d53]">
                  {card.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyUsBox;

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const IndustriesServe = () => {
  const industries = [
    {
      name: "Health Care →",
      link: "/industries/medical-facilities?title=Medical Facilities&subtitle=Cleaning Services for a Healthy, Sanitized Environment&image=url",
      imageUrl:
        "https://images.unsplash.com/photo-1628373961147-87e7f514a35f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80",
    },
    {
      name: "Education →",
      link: "/industries/education-schools?title=Education / Schools&subtitle=Comprehensive Cleaning for Educational Facilities&image=url",
      imageUrl:
        "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80",
    },
    {
      name: "Gas Station →",
      link: "/industries/gas-station?title=Gas Station&subtitle=Quality Cleaning for a Pristine and Safe Gas Station Environment&image=url",
      imageUrl:
        "https://images.unsplash.com/photo-1613986406535-a8371a573ac5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    },
    // More industries...
  ];

  return (
    <div className="bg-[#f5f5f5] py-16">
      <div className="mx-auto px-6 lg:px-16">
        <div className="mx-auto max-w-2xl text-center">
          <motion.h2
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="text-3xl font-base tracking-wide text-[#900c3e] sm:text-6xl"
          >
            Industries Served
          </motion.h2>
        </div>
        <motion.ul
          initial={{ scale: 0 }}
          transition={{ ease: "easeOut", duration: 0.6 }}
          whileInView={{ scale: 1 }}
          viewport={{ once: true }}
          className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-16 lg:mx-0 lg:max-w-none lg:grid-cols-3"
        >
          {industries.map((industry) => (
            <li key={industry.name}>
              <Link to={industry.link}>
                <img
                  className="aspect-[3/2] w-full object-cover hover:shadow-[0_0px_60px_-15px_rgba(0,0,0,0.6)]"
                  src={industry.imageUrl}
                  alt=""
                />
                <h3 className="mt-8 text-2xl font-base tracking-wide leading-6 text-[#474d53] hover:text-[#900c3e]">
                  {industry.name}
                </h3>
              </Link>
            </li>
          ))}
        </motion.ul>
      </div>
    </div>
  );
};

export default IndustriesServe;

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const LocationIcons = () => {
  const industries = [
    {
      name: "Louisville",
      imageSrc: "https://cdn-icons-png.flaticon.com/512/1018/1018524.png",
      description: "Our home base for exceptional cleaning services.",
      link: "/locations/louisville?title=Louisville",
    },
    {
      name: "Atlanta",
      imageSrc: "https://cdn-icons-png.flaticon.com/128/1018/1018573.png",
      description:
        "Experience the difference with our eco-friendly cleaning solutions.",
      link: "/locations/Atlanta?title=Atlanta",
    },
  ];

  return (
    <div className="bg-gray-50">
      <h2 className="sr-only">Our perks</h2>
      <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-12 gap-x-28 px-4 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
          {industries.map((industry) => (
            <Link to={industry.link} key={industry.name} className="sm:flex">
              <div className="sm:flex-shrink-0">
                <div className="flow-root">
                  <motion.img
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.5 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="h-12 w-12"
                    src={industry.imageSrc}
                    alt=""
                  />
                </div>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <motion.h3
                  initial={{ scale: 0 }}
                  transition={{ ease: "easeOut", duration: 0.6 }}
                  whileInView={{ scale: 1 }}
                  viewport={{ once: true }}
                  className="text-2xl font-semi-bold tracking-tight text-[#474d53] hover:text-[#900c3e]"
                >
                  {industry.name}
                </motion.h3>
                <motion.p
                  initial={{ scale: 0 }}
                  transition={{ ease: "easeOut", duration: 0.7 }}
                  whileInView={{ scale: 1 }}
                  viewport={{ once: true }}
                  className="mt-2 text-md text-[#474d53]"
                >
                  {industry.description}
                </motion.p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LocationIcons;

import { motion } from "framer-motion";

const Logos = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <motion.h2
          initial={{ scale: 0 }}
          transition={{ ease: "easeOut", duration: 0.4 }}
          whileInView={{ scale: 1 }}
          viewport={{ once: true }}
          className="text-center font-base font-bold tracking-wide text-3xl leading-8 text-[#900c3e] sm:text-6xl "
        >
          Companies That Trust Us
        </motion.h2>
        {/* When you want to add more columns just add by increasing grid cols */}
        <div className="mx-auto mt-16 grid max-w-lg grid-cols-2 items-center gap-x-8 gap-y- sm:max-w-xl sm:grid-cols-2 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <motion.img
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.4 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="col-span-2 max-h-40 w-full object-cover  lg:col-span-1"
            src="https://d92mrp7hetgfk.cloudfront.net/images/sites/misc/savannah_c_of_art_and_design-1/standard.png?1548464114"
            alt=""
            width={158}
            height={48}
          />
          <motion.img
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.4 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="col-span-2 max-h-40 w-full object-contain lg:col-span-1"
            src="https://assets-global.website-files.com/5ce11396d0cadb67eb2cac0e/5f7ce825b5fb4a7ac9eaf32c_7-Eleven-Logo-p-500.png"
            alt=""
            width={158}
            height={48}
          />

          <motion.img
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.4 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="col-span-2 max-h-40 w-full object-contain lg:col-span-1"
            src="https://i0.wp.com/apresgroup.com/wp-content/uploads/2016/08/teal-logo.png?fit=1048%2C457&ssl=1"
            alt=""
            width={158}
            height={48}
          />
        </div>
      </div>
    </div>
  );
};

export default Logos;

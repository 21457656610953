import { GiCheckMark } from "react-icons/gi";
import { motion } from "framer-motion";

const Includes = () => {
  const stats = [
    { id: 1, name: "Bespoke Cleaning Plans", value: "8,000+" },
    { id: 2, name: "Eco-Friendly Products", value: "3%" },
    { id: 3, name: "Certified Professionals", value: "99.9%" },
    { id: 4, name: "Flexible, Reliable Service", value: "$70M" },
  ];

  return (
    <div
      id="includes-image"
      className="bg-[#e0c4bd] py-24 sm:py-32 object-cover"
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <motion.h6
              initial={{ scale: 0 }}
              transition={{ ease: "easeOut", duration: 0.4 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              className="text-3xl font-base tracking-wide text-white sm:text-6xl"
            >
              Discover Our Comprehensive Janitorial Services
            </motion.h6>
            {/* <p className="mt-4 text-lg leading-8 text-gray-600">
              Lorem ipsum dolor sit amet consect adipisicing possimus.
            </p> */}
          </div>
          <motion.dl
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.4 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4"
          >
            {stats.map((stat) => (
              <div key={stat.id} className="flex flex-col bg-gray-400/5 p-8">
                <h6 className="text-lg font-base tracking-wide leading-6 text-white mt-12">
                  {stat.name}
                </h6>
                <dd className="order-first text-5xl font-semibold tracking-tight text-green-500 flex justify-center">
                  <GiCheckMark />
                </dd>
              </div>
            ))}
          </motion.dl>
        </div>
      </div>
    </div>
  );
};

export default Includes;

import IndustryTypes from "../IndustryTypes";
import Hero from "../Hero";
import Includes from "../Includes";
import MoreInfoService from "../MoreInfoService";
import RequestQuote from "../RequestQuote";
import PicRight from "../PicRight";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import gyms from "../../images/gyms.jpg"
import { motion } from "framer-motion";

const Health = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = new URLSearchParams(location.search).get("title");
  const subtitle = new URLSearchParams(location.search).get("subtitle");
  const image = new URLSearchParams(location.search).get("image");
  const description = new URLSearchParams(location.search).get("description");

  const cards = [
    {
      name: "Why Us?",
      description: "At Tower Cleaning Services, we understand the importance of a clean and hygienic gym for the health and satisfaction of your clients. Our team of professional cleaners is dedicated to providing a comprehensive and customized cleaning solution for each gym we service. We leave no stone unturned and pay attention to every inch of your facility to eliminate any bacterial growth. Our services cover not only the areas that clients use but also the administrative spaces in your gym. We use high-quality cleaning products to ensure that every speck of dirt is eliminated, leaving your gym spotless and smelling fresh. While it's courteous to clean equipment after use, nothing compares to a deep clean from a professional cleaning company. At Tower Cleaning Services, we prioritize the health and satisfaction of your clients by providing a clean and hygienic gym environment."    },
  ];

  return (
    <div>
      <Hero
        title={title}
        subtitle={subtitle}
        button={"Call to Book →"}
        link={"/"}
        picture={gyms}
      />
      <PicRight
        subtitle={"Customized Solutions for a Sanitized Health Club"}
        title={"Expert Cleaning for Gyms and Fitness Centers"}
        description={
          "We offer tailored cleaning solutions for health clubs and gyms, ensuring the safety and wellness of your members and staff. Our experienced cleaning team uses the latest techniques and tools to deliver a spotless, sanitized environment.\n\nFrom workout areas to reception areas, we customize our cleaning plans to meet your specific needs and budget. Contact us today to learn more about our expert cleaning services."
        }
        button={"Call for a Quote"}
        picture={
          "https://images.unsplash.com/photo-1583947581924-860bda6a26df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1167&q=80"
        }
        color={"#f5f5f5"}
      />
      <Includes />
      <>
      <div className="relative isolate overflow-hidden py-10 ">
        <svg
          viewBox="0 0 1097 845"
          aria-hidden="true"
          className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
        >
          <path
            fill="url(#7c63f5ae-130c-4c0f-963f-50ac7fe8d2e1)"
            fillOpacity=".2"
            d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
          />
        </svg>
        <div className="mx-auto px-6 lg:px-32">
          <motion.h2
            initial={{ scale: 0 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
            whileInView={{ scale: 1 }}
            viewport={{ once: true }}
            className="mb-5 text-3xl font-base tracking-wide text-[#900c3e] sm:text-6xl"
          >
            Why choose us for Gym Cleaning Services
          </motion.h2>
          <div className="mx-auto max-w-2xl lg:mx-0"></div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 bg-[#f5f5f5] gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:gap-1">
            {cards.map((card) => (
              <div key={card.name} className="justify-center flex gap-x-4] p-6">
                <div className="text-base leading-7 ">
                  <motion.h3
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.6 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="font-bold text-black hover:text-[#82123f]"
                  >
                    {card.name}
                  </motion.h3>
                  <motion.p
                    id="faqText"
                    initial={{ scale: 0 }}
                    transition={{ ease: "easeOut", duration: 0.7 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    className="mt-2  text-[#474d53] whitespace-pre-line"
                  >
                    {card.description}
                  </motion.p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <motion.div
        initial={{ scale: 0 }}
        transition={{ ease: "easeOut", duration: 0.8 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        className="text-center mb-10"
      >
      </motion.div>
    </>
      <RequestQuote />
    </div>
  );
};

export default Health;

import IndustryTypes from "../IndustryTypes";
import Hero from "../Hero";
import Includes from "../Includes";
import MoreInfoService from "../MoreInfoService";
import RequestQuote from "../RequestQuote";
import PicRight from "../PicRight";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Retails from "../../images/Retails.jpg"

const Retail = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = new URLSearchParams(location.search).get("title");
  const subtitle = new URLSearchParams(location.search).get("subtitle");
  const image = new URLSearchParams(location.search).get("image");
  const description = new URLSearchParams(location.search).get("description");

  return (
    <div>
      <Hero
        title={title}
        subtitle={subtitle}
        button={"Call to Book →"}
        link={"/"}
        picture={Retails}
      />
      <PicRight
        subtitle={
          "Create a Spotless and Inviting Shopping Experience with Tower Cleaning Services"
        }
        title={"Expert Cleaning Solutions for Retail Spaces"}
        description={`At Tower Cleaning Services, we provide expert cleaning solutions to keep your retail space spotless and inviting for customers. Our experienced team of professionals is equipped with the latest tools and techniques to deliver a tailored cleaning plan that meets your specific requirements and exceeds your expectations.\n\nFrom retail floors and shelves to restrooms and break rooms, we customize our cleaning plans to fit your needs and budget. Contact us today to learn more about our expert retail cleaning solutions.`}
        button={"Call for a Quote"}
        picture={
          "https://s3.gomedia.ws/wp-content/uploads/sites/78/2019/12/02163421/retailstorecleaning.jpeg"
        }
        color={"#f5f5f5"}
      />
      <Includes />
      <RequestQuote />
    </div>
  );
};

export default Retail;
